import DOMPurify from "dompurify";
import moment from "moment";
import React, { PropsWithChildren } from "react";
import { msalInstance } from "../../../..";
import { getFormatedDate } from "../../../../RAFComponents/Inputs/RFFUtils";
import CustomCardWidget from "../../../../RAFComponents/Navigation/CustomCardWidget";
import RAFDeletedRecordState from "../../../../RAFComponents/Navigation/RAFDeletedRecordState";
import RAFDetailFieldCustom from "../../../../RAFComponents/Navigation/RAFDetailFieldCustom";
import RAFDetailsValueWithSeparator from "../../../../RAFComponents/Navigation/RAFDetailsValueWithSeparator/RAFDetailsValueWithSeparator";
import RAFRecordInfo from "../../../../RAFComponents/Navigation/RAFRecordInfo";
import {
  isRAFMaxDate,
  isRAFMinDate,
} from "../../../../RAFComponents/helpers/AppHelper";
import {
  getDisplayNameByModuleName
} from "../../../../RAFComponents/helpers/RAFMenuHelper";
import {
  IsNotNullOrWhiteSpace,
  convertUTCDateToLocalTimezone,
  isNotEmptyArray,
  isNotNullAndUndefined,
  isNullOrUndefined
} from "../../../../RAFComponents/helpers/utils";
import {
  BrowserIsDevice,
  RAFActionStatus,
  RAFStatusNameWithColor,
  RAFTaskSubType,
  RAFTaskTypeWithColor,
} from "../../../../constants/Common/Constants";
import { RAFEntityName } from "../../../../constants/Common/EntityConstants";
import { getTaskDisplayDateAndBadge } from "../../../ActiveContacts/Task/TaskHelper";
import { TaskRow } from "../../../ActiveContacts/Task/TaskRow";
import "./PlannerTaskStyle.scss";

interface IProps {
  plannerList?: TaskRow[];
  rowClick?: (taskRow: TaskRow) => void;
  isDashboard?: boolean;
}

function PlannerListTimelineContent({
  plannerList,
  isDashboard,
  ...props
}: PropsWithChildren<IProps>) {
  return (
    <ul className={"timeline__ul timeline__container planner_timeline"}>
      {isNotEmptyArray(plannerList) ? (
        plannerList.map((taskRow, index) => {
          const taskDisplayDateAndBadge = getTaskDisplayDateAndBadge(
            taskRow.TaskDate,
            taskRow.TaskType,
            true
          );
          let taskDate = taskDisplayDateAndBadge.taskDate;
          let dateBadge = taskDisplayDateAndBadge.dateBadge;
          const todoTaskColor = taskDisplayDateAndBadge.taskTextColor;
          let headerContent: string | React.ReactElement = "";
          let headerTemplate;
          let bodyTemplate;
          let createdByName = IsNotNullOrWhiteSpace(taskRow.CreatedBy)
            ? taskRow.CreatedBy === msalInstance.currentUserName &&
              taskRow.CreatedByUID === msalInstance.currentUserId
              ? "You"
              : taskRow.CreatedBy
            : "Not set";
          let assigneeName = IsNotNullOrWhiteSpace(taskRow.Assignee)
            ? taskRow.Assignee === taskRow.CreatedBy &&
              taskRow.CreatedByUID === taskRow.AssigneeUID
              ? ""
              : ` to ${taskRow.Assignee}`
            : "";
          let descriptionContent = (
            <span
              dangerouslySetInnerHTML={{
                __html:
                  taskRow !== null
                    ? DOMPurify.sanitize(taskRow.Description)
                    : undefined,
              }}
              className="remove_paragraph_margin body_2_light description-text note_card_content ecllipseSecondLine ecllipse_4"
            ></span>
          );

          let relatedToTypeDisplayName;
          if (isNullOrUndefined(taskRow.RelatedToTypeDisplayName)) {
            relatedToTypeDisplayName = getDisplayNameByModuleName(
              taskRow.RelatedToType
            );
          }

          let relatedTitle;
          if (
            IsNotNullOrWhiteSpace(taskRow.RelatedToTypeDisplayName) &&
            isDashboard &&
            isNotNullAndUndefined(taskRow.RelatedTo)
          ) {
            relatedTitle = (
              <>
                <span className="body_2_light ms-1">
                  for {taskRow.RelatedToTypeDisplayName}:
                </span>

                {isNotNullAndUndefined(taskRow.RelatedTo) && (
                  <span className="subtitle_2 ps-1">{taskRow.RelatedTo}</span>
                )}
              </>
            );
          } else if (isNotNullAndUndefined(taskRow.RelatedTo) && isDashboard) {
            relatedTitle = (
              <>
                <span className="body_2_light ms-1">
                  for {relatedToTypeDisplayName}:
                </span>
                <span className="subtitle_2 ps-1">{taskRow.RelatedTo}</span>
              </>
            );
          }
          if (taskRow.TaskType === RAFTaskTypeWithColor["To-Do"].DisplayName) {
            headerContent = (
              <div className="body_2_light">
                <div className="row g-1 align-items-baseline">
                  <div className="col-auto">
                    <div className=""> {createdByName}</div>
                  </div>
                  <div className="col-auto">planned a</div>
                  <div className="col-auto">
                    <div
                      className={`body_2 semi_bold`}
                      style={{
                        color: RAFTaskTypeWithColor["To-Do"].Color,
                      }}
                    >
                      {RAFTaskTypeWithColor["To-Do"].DisplayName}
                    </div>
                  </div>
                  {IsNotNullOrWhiteSpace(assigneeName) ? (
                    <div className="col-auto">{assigneeName}</div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            );
            headerTemplate = (
              <div className="row gx-2">
                <div className="col-auto">
                  <div className="planner_timeline_date_card">
                    <div className="planner_timeline_date_card_month">
                      {getFormatedDate(taskRow.TaskDate, "MMM")}
                    </div>
                    <div className="planner_timeline_date_card_date">
                      {getFormatedDate(taskRow.TaskDate, "DD")}
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="subtitle_1 semi_bold content_neutral_dark ecllipseFirstLine">
                    {headerContent}
                  </div>
                  <RAFDetailsValueWithSeparator outerClassName="h-26">
                    <RAFDetailFieldCustom
                      title="Subject:"
                      value={taskRow.Title}
                      labelDivClassName="col-auto"
                      valueDivClassName="col-auto"
                      labelClassName="body_2_light"
                      valueClassName="body_2"
                      rowClassName="gx-1"
                    ></RAFDetailFieldCustom>
                  </RAFDetailsValueWithSeparator>
                </div>
              </div>
            );
            bodyTemplate = (
              <div className="row gx-2">
                <div className="col-auto">
                  {taskRow.TaskStatus === RAFActionStatus.Completed ? (
                    <span className="raf_badge raf_badge_success">
                      Completed
                    </span>
                  ) : (
                    <span
                      className={`raf_badge ${dateBadge} surface_${todoTaskColor}${taskDate === "Unplanned" ? "_light" : "_base"
                        }`}
                    >
                      {taskDate}
                    </span>
                  )}
                  {/* <div className="planner_timeline_date_card">
                        <div
                          className={`planner_timeline_date_card_month surface_${todoTaskColor}_semi_dark`}
                        >
                          {getFormatedDate(taskRow.TaskDate, "MMM")}
                        </div>
                        <div className="planner_timeline_date_card_date">
                          {getFormatedDate(taskRow.TaskDate, "DD")}
                        </div>
                      </div> */}
                </div>
                <div className="col">
                  <div className="subtitle_2 content_neutral_dark ecllipseFirstLine">
                    {taskRow.Title}
                  </div>
                </div>
                {IsNotNullOrWhiteSpace(taskRow.Description) && (
                  <div className="col-12">
                    <div className="mt-2 p-2 py-1 surface_neutral_light">
                      {descriptionContent}
                    </div>
                  </div>
                )}
              </div>
            );
          } else if (
            taskRow.TaskType === RAFTaskTypeWithColor["Appointment"].DisplayName
          ) {
            headerContent = (
              <div className="body_2_light">
                <div className="row g-1 align-items-baseline">
                  <div className="col-auto">
                    <div className=""> {createdByName}</div>
                  </div>
                  <div className="col-auto">scheduled an</div>
                  <div className="col-auto">
                    <div
                      className={`body_2 semi_bold`}
                      style={{
                        color: RAFTaskTypeWithColor["Appointment"].Color,
                      }}
                    >
                      {RAFTaskTypeWithColor["Appointment"].DisplayName}
                    </div>
                  </div>
                  {IsNotNullOrWhiteSpace(taskRow.TaskDate) &&
                    !isRAFMaxDate(
                      taskRow.TaskDate,
                      convertUTCDateToLocalTimezone(taskRow.TaskDate)
                    ) ? (
                    <div className="col-auto">
                      <span className="">
                        {moment(convertUTCDateToLocalTimezone(taskRow.TaskDate))
                          .subtract(20, "s")
                          .fromNow()}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            );
          } else if (
            taskRow.TaskType === RAFTaskTypeWithColor["Action"].DisplayName
          ) {
            headerContent = (
              <div className="body_2_light">
                <div className="row g-1 align-items-baseline">
                  <div className="col-auto">
                    <div className=""> {createdByName}</div>
                  </div>
                  <div className="col-auto">created a</div>
                  <div className="col-auto">
                    <div
                      className={`body_2 semi_bold`}
                      style={{
                        color: RAFTaskTypeWithColor["Action"].Color,
                      }}
                    >
                      {`${taskRow.SubType} ${RAFTaskTypeWithColor["Action"].DisplayName}`}
                    </div>
                  </div>
                  {IsNotNullOrWhiteSpace(taskRow.TaskDate) &&
                    !isRAFMaxDate(
                      taskRow.TaskDate,
                      convertUTCDateToLocalTimezone(taskRow.TaskDate)
                    ) ? (
                    isRAFMinDate(
                      taskRow.TaskDate,
                      convertUTCDateToLocalTimezone(taskRow.TaskDate)
                    ) ? (
                      <div className="col-auto">
                        <span className={`raf_badge raf_xsm ${dateBadge}`}>
                          {taskDate}
                        </span>
                      </div>
                    ) : (
                      <div className="col-auto">
                        <span className="">
                          {moment(
                            convertUTCDateToLocalTimezone(taskRow.TaskDate)
                          )
                            .subtract(20, "s")
                            .fromNow()}
                        </span>
                      </div>
                    )
                  ) : (
                    ""
                  )}
                  {IsNotNullOrWhiteSpace(assigneeName) ? (
                    <div className="col-auto">{assigneeName}</div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            );
          } else if (
            taskRow.TaskType === RAFTaskTypeWithColor["Call"].DisplayName
          ) {
            headerContent = (
              <div className="body_2_light">
                <div className="row g-1 align-items-baseline">
                  <div className="col-auto">
                    <div className=""> {createdByName}</div>
                  </div>
                  <div className="col-auto">
                    {taskRow.SubType === RAFTaskSubType.Inbound
                      ? "received a"
                      : "made an outbound"}
                  </div>
                  <div className="col-auto">
                    <div
                      className={`body_2 semi_bold`}
                      style={{
                        color: RAFTaskTypeWithColor["Call"].Color,
                      }}
                    >
                      {RAFTaskTypeWithColor["Call"].DisplayName}
                    </div>
                  </div>
                  {IsNotNullOrWhiteSpace(taskRow.TaskDate) &&
                    !isRAFMaxDate(
                      taskRow.TaskDate,
                      convertUTCDateToLocalTimezone(taskRow.TaskDate)
                    ) ? (
                    <div className="col-auto">
                      <span className="">
                        {moment(convertUTCDateToLocalTimezone(taskRow.TaskDate))
                          .subtract(20, "s")
                          .fromNow()}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            );
          } else if (
            taskRow.TaskType === RAFTaskTypeWithColor["Note"].DisplayName
          ) {
            headerContent = (
              <div className="body_2_light">
                <div className="row g-1 align-items-baseline">
                  <div className="col-auto">
                    <div className=""> {createdByName}</div>
                  </div>
                  <div className="col-auto">added a</div>
                  <div className="col-auto">
                    <div
                      className={`body_2 semi_bold`}
                      style={{
                        color: RAFTaskTypeWithColor["Note"].Color,
                      }}
                    >
                      {RAFTaskTypeWithColor["Note"].DisplayName}
                    </div>
                  </div>
                  {IsNotNullOrWhiteSpace(taskRow.CreatedDate) &&
                    !isRAFMaxDate(
                      taskRow.CreatedDate,
                      convertUTCDateToLocalTimezone(taskRow.CreatedDate)
                    ) ? (
                    <div className="col-auto">
                      <span className="">
                        {moment(
                          convertUTCDateToLocalTimezone(taskRow.CreatedDate)
                        )
                          .subtract(20, "s")
                          .fromNow()}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            );
            headerTemplate = (
              <>
                <div className="subtitle_1 semi_bold content_neutral_dark ecllipseFirstLine">
                  {headerContent}
                </div>
                <RAFDetailsValueWithSeparator outerClassName="h-26">
                  <RAFDetailFieldCustom
                    title="Subject:"
                    value={taskRow.Title}
                    labelDivClassName="col-auto"
                    valueDivClassName="col-auto"
                    labelClassName="body_2_light"
                    valueClassName="body_2"
                    rowClassName="gx-1"
                  ></RAFDetailFieldCustom>
                </RAFDetailsValueWithSeparator>
              </>
            );
            bodyTemplate = (
              <div>
                <div className="row g-0 justify-content-end align-items-center">
                  <div className="col">
                    <div className="subtitle_1 semi_bold content_neutral_dark ecllipseFirstLine">
                      {taskRow.Title}
                    </div>
                  </div>
                  <div className="col-auto">
                    <span className="note_pin_outer_div">
                      {taskRow.Pinned ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_5457_81698)">
                            <path
                              d="M22.0642 9.74884L17.0513 14.7785C17.4788 15.9663 17.656 17.9557 15.8148 20.4035C15.686 20.5756 15.5216 20.7179 15.3328 20.8207C15.1439 20.9234 14.9352 20.9842 14.7207 20.9988C14.6851 20.9988 14.6504 20.9988 14.6148 20.9988C14.4177 20.9989 14.2225 20.9601 14.0404 20.8846C13.8583 20.8092 13.6929 20.6986 13.5535 20.5591L9.02915 16.0301L5.03259 20.0295C4.89186 20.1702 4.70098 20.2493 4.50196 20.2493C4.30294 20.2493 4.11207 20.1702 3.97134 20.0295C3.8306 19.8887 3.75154 19.6979 3.75154 19.4988C3.75154 19.2998 3.8306 19.1089 3.97134 18.9682L7.97071 14.9717L3.44259 10.4435C3.29375 10.2945 3.17796 10.1158 3.10276 9.91906C3.02757 9.72232 2.99465 9.51195 3.00614 9.30165C3.01764 9.09134 3.0733 8.88581 3.16949 8.69844C3.26569 8.51107 3.40026 8.34606 3.56446 8.21415C5.94759 6.29134 8.22852 6.66915 9.22696 6.98227L14.252 1.93946C14.3913 1.80014 14.5566 1.68962 14.7386 1.61421C14.9206 1.53881 15.1157 1.5 15.3127 1.5C15.5098 1.5 15.7048 1.53881 15.8868 1.61421C16.0689 1.68962 16.2342 1.80014 16.3735 1.93946L22.0632 7.62821C22.3444 7.90936 22.5025 8.29067 22.5027 8.68833C22.5029 9.08598 22.3451 9.46744 22.0642 9.74884Z"
                              fill="#F65C06"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_5457_81698">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <g clip-path="url(#clip0_5457_81722)">
                            <path
                              d="M22.0642 9.74884L17.0513 14.7785C17.4788 15.9663 17.656 17.9557 15.8148 20.4035C15.686 20.5756 15.5216 20.7179 15.3328 20.8207C15.1439 20.9234 14.9352 20.9842 14.7207 20.9988C14.6851 20.9988 14.6504 20.9988 14.6148 20.9988C14.4177 20.9989 14.2225 20.9601 14.0404 20.8846C13.8583 20.8092 13.6929 20.6986 13.5535 20.5591L9.02915 16.0301L5.03259 20.0295C4.89186 20.1702 4.70098 20.2493 4.50196 20.2493C4.30294 20.2493 4.11207 20.1702 3.97134 20.0295C3.8306 19.8887 3.75154 19.6979 3.75154 19.4988C3.75154 19.2998 3.8306 19.1089 3.97134 18.9682L7.97071 14.9717L3.44259 10.4435C3.29375 10.2945 3.17796 10.1158 3.10276 9.91906C3.02757 9.72232 2.99465 9.51195 3.00614 9.30165C3.01764 9.09134 3.0733 8.88581 3.16949 8.69844C3.26569 8.51107 3.40026 8.34606 3.56446 8.21415C5.94759 6.29134 8.22852 6.66915 9.22696 6.98227L14.252 1.93946C14.3913 1.80014 14.5566 1.68962 14.7386 1.61421C14.9206 1.53881 15.1157 1.5 15.3127 1.5C15.5098 1.5 15.7048 1.53881 15.8868 1.61421C16.0689 1.68962 16.2342 1.80014 16.3735 1.93946L22.0632 7.62821C22.3444 7.90936 22.5025 8.29067 22.5027 8.68833C22.5029 9.08598 22.3451 9.46744 22.0642 9.74884Z"
                              fill="#C5C5C5"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_5457_81722">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      )}
                    </span>
                  </div>
                </div>
                {IsNotNullOrWhiteSpace(taskRow.Description) && (
                  <div className="mt-2 p-2 py-1 surface_neutral_light">
                    {descriptionContent}
                  </div>
                )}
              </div>
            );
          }
          headerContent = (
            <div className="body_2_light">
              <RAFDetailsValueWithSeparator outerClassName="h-auto raf_md">
                <div className="row g-1 align-items-baseline">
                  <div className="col-auto">
                    <div className=""> {createdByName}</div>
                  </div>
                  <div className="col-auto">created</div>
                  <div className="col-auto">
                    <div
                      className={`body_2 semi_bold`}
                      style={{
                        color: RAFTaskTypeWithColor[taskRow.TaskType]?.Color,
                      }}
                    >
                      {RAFTaskTypeWithColor[taskRow.TaskType]?.DisplayName}
                    </div>
                  </div>
                  {/* {IsNotNullOrWhiteSpace(assigneeName) ? (
                  <div className="col-auto">
                    to
                    <span className="ps-1">{assigneeName}</span>
                  </div>
                ) : (
                  ""
                )} */}
                </div>

                <div className="row gx-1 align-items-baseline">
                  {!BrowserIsDevice && (
                    <div className="col-auto">
                      <span className="body_3 content_neutral_base">
                        Updated:
                      </span>
                    </div>
                  )}
                  <div className="col-auto">
                    <RAFRecordInfo
                      createdBy={taskRow.CreatedBy}
                      createdDate={taskRow.CreatedDate}
                      modifiedDate={taskRow.ModifiedDate}
                      modifiedBy={taskRow.ModifiedBy}
                      lastActivityDate={taskRow.LastActivityDate}
                      dropdownBtnClassName="custom-button-sm p-0 remove-minWidth"
                      hideClockIcon
                      // {...(!BrowserIsDevice ? { content: "Updated: " } : {})}
                      spanContentClassName="body_3 content_neutral_dark medium text-decoration-underline"
                    // spanContentClassName="content_neutral_base recordInfo_text fw-bold"
                    ></RAFRecordInfo>
                  </div>
                </div>
              </RAFDetailsValueWithSeparator>
            </div>
          );
          headerTemplate = (
            <div className="row gx-2">
              <div className="col-auto">
                <div className="planner_timeline_date_card">
                  <div className="planner_timeline_date_card_month">
                    {getFormatedDate(taskRow.TaskDate, "MMM")}
                  </div>
                  <div className="planner_timeline_date_card_date">
                    {getFormatedDate(taskRow.TaskDate, "DD")}
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="subtitle_1 semi_bold content_neutral_dark ecllipseFirstLine">
                  {headerContent}
                </div>
                <RAFDetailsValueWithSeparator outerClassName="h-26">
                  <RAFDetailFieldCustom
                    title="Subject:"
                    value={taskRow.Title}
                    labelDivClassName="col-auto"
                    valueDivClassName="col-auto"
                    labelClassName="body_2_light"
                    valueClassName="body_2"
                    rowClassName="gx-1"
                  ></RAFDetailFieldCustom>
                </RAFDetailsValueWithSeparator>
              </div>
            </div>
          );
          bodyTemplate = (
            <div className="row gx-2 align-items-center">
              <div className="col-auto">
                {taskRow.TaskStatus === RAFActionStatus.Completed ? (
                  <span
                    className={`raf_badge ${taskRow.TaskType ===
                      RAFTaskTypeWithColor["To-Do"].DisplayName
                      ? ` raf_badge_success`
                      : `surface_neutral_light`
                      }`}
                  >
                    {taskDate === "Unplanned" &&
                      taskRow.TaskType !==
                      RAFTaskTypeWithColor["To-Do"].DisplayName
                      ? "Not set"
                      : taskDate}
                  </span>
                ) : (
                  <span
                    className={`raf_badge ${dateBadge} ${taskRow.TaskType ===
                      RAFTaskTypeWithColor["To-Do"].DisplayName
                      ? `surface_${todoTaskColor}${taskDate === "Unplanned" ? "_light" : "_base"
                      }`
                      : `surface_neutral_light`
                      }`}
                  >
                    {taskDate === "Unplanned" &&
                      taskRow.TaskType !==
                      RAFTaskTypeWithColor["To-Do"].DisplayName
                      ? "Not set"
                      : taskDate}
                  </span>
                )}
                {/* <div className="planner_timeline_date_card">
                      <div
                        className={`planner_timeline_date_card_month surface_${todoTaskColor}_semi_dark`}
                      >
                        {getFormatedDate(taskRow.TaskDate, "MMM")}
                      </div>
                      <div className="planner_timeline_date_card_date">
                        {getFormatedDate(taskRow.TaskDate, "DD")}
                      </div>
                    </div> */}
              </div>
              <div className="col">
                <div className="subtitle_2 content_neutral_dark ecllipseFirstLine">
                  {taskRow.Title}
                </div>
              </div>
              {IsNotNullOrWhiteSpace(taskRow.Description) && (
                <div className="col-12">
                  <div className="mt-2 p-2 py-1 surface_neutral_light">
                    {descriptionContent}
                  </div>
                </div>
              )}
            </div>
          );

          //new phrase for the timeline
          if (taskRow.TaskType === RAFTaskTypeWithColor["To-Do"].DisplayName) {
            let firstTitle = "";
            let secondTitle = "";
            if (
              taskRow.CreatedBy !== msalInstance.currentUserName &&
              taskRow.CreatedByUID !== msalInstance.currentUserId &&
              taskRow.AssigneeUID !== msalInstance.currentUserId &&
              taskRow.TaskStatus !== RAFActionStatus.Completed
            ) {
              if (taskRow.CreatedByUID === taskRow.AssigneeUID) {
                firstTitle = taskRow.Assignee + " planned a ";
              } else {
                firstTitle = taskRow.CreatedBy + " assigned a ";
                secondTitle = " to " + taskRow.Assignee;
              }
            } else if (
              taskRow.CreatedBy !== msalInstance.currentUserName &&
              taskRow.CreatedByUID !== msalInstance.currentUserId &&
              taskRow.AssigneeUID !== msalInstance.currentUserId &&
              taskRow.TaskStatus === RAFActionStatus.Completed
            ) {
              if (taskRow.CreatedByUID === taskRow.AssigneeUID) {
                firstTitle = taskRow.Assignee + " completed the ";
              } else {
                firstTitle = taskRow.Assignee + " completed the ";
                secondTitle = " assigned by " + taskRow.CreatedBy;
              }
            } else if (
              taskRow.CreatedBy === msalInstance.currentUserName &&
              taskRow.CreatedByUID === msalInstance.currentUserId &&
              taskRow.AssigneeUID === msalInstance.currentUserId &&
              taskRow.TaskStatus !== RAFActionStatus.Completed
            ) {
              firstTitle = "You have planned a ";
            } else if (
              taskRow.CreatedBy === msalInstance.currentUserName &&
              taskRow.CreatedByUID === msalInstance.currentUserId &&
              taskRow.AssigneeUID !== msalInstance.currentUserId &&
              taskRow.TaskStatus !== RAFActionStatus.Completed
            ) {
              firstTitle = "You have assigned a ";
              secondTitle = " to " + taskRow.Assignee;
            } else if (
              taskRow.CreatedBy !== msalInstance.currentUserName &&
              taskRow.CreatedByUID !== msalInstance.currentUserId &&
              taskRow.AssigneeUID === msalInstance.currentUserId &&
              taskRow.TaskStatus !== RAFActionStatus.Completed
            ) {
              firstTitle = taskRow.CreatedBy + " assigned a ";
            } else if (
              taskRow.CreatedBy !== msalInstance.currentUserName &&
              taskRow.CreatedByUID !== msalInstance.currentUserId &&
              taskRow.AssigneeUID !== msalInstance.currentUserId &&
              taskRow.TaskStatus !== RAFActionStatus.Completed
            ) {
              firstTitle = taskRow.Assignee + " planned a ";
            } else if (
              taskRow.CreatedBy !== msalInstance.currentUserName &&
              taskRow.CreatedByUID !== msalInstance.currentUserId &&
              taskRow.AssigneeUID !== msalInstance.currentUserId &&
              taskRow.AssigneeUID === taskRow.CreatedByUID &&
              taskRow.TaskStatus !== RAFActionStatus.Completed
            ) {
              firstTitle = taskRow.Assignee + " planned a ";
            } else if (
              taskRow.CreatedBy !== msalInstance.currentUserName &&
              taskRow.CreatedByUID !== msalInstance.currentUserId &&
              taskRow.AssigneeUID !== msalInstance.currentUserId &&
              taskRow.AssigneeUID !== taskRow.CreatedByUID &&
              taskRow.TaskStatus !== RAFActionStatus.Completed
            ) {
              firstTitle = taskRow.CreatedBy + " assigned a ";
              secondTitle = " to " + taskRow.Assignee;
            } else if (
              taskRow.CreatedBy === msalInstance.currentUserName &&
              taskRow.CreatedByUID === msalInstance.currentUserId &&
              taskRow.AssigneeUID === msalInstance.currentUserId &&
              taskRow.TaskStatus === RAFActionStatus.Completed
            ) {
              firstTitle = "You completed the ";
            } else if (
              taskRow.CreatedBy === msalInstance.currentUserName &&
              taskRow.CreatedByUID === msalInstance.currentUserId &&
              taskRow.AssigneeUID !== msalInstance.currentUserId &&
              taskRow.TaskStatus === RAFActionStatus.Completed
            ) {
              firstTitle = taskRow.Assignee + " completed the  ";
              secondTitle = " assigned by you";
            } else if (
              taskRow.CreatedBy !== msalInstance.currentUserName &&
              taskRow.CreatedByUID !== msalInstance.currentUserId &&
              taskRow.AssigneeUID !== msalInstance.currentUserId &&
              taskRow.TaskStatus === RAFActionStatus.Completed
            ) {
              firstTitle = taskRow.Assignee + " completed the ";
            } else if (
              taskRow.CreatedBy !== msalInstance.currentUserName &&
              taskRow.CreatedByUID !== msalInstance.currentUserId &&
              taskRow.AssigneeUID === msalInstance.currentUserId &&
              taskRow.TaskStatus === RAFActionStatus.Completed
            ) {
              firstTitle = "You completed the ";
              secondTitle = " assigned by " + taskRow.CreatedBy;
            }

            headerContent = (
              <div className="body_2_light">
                <span className="">{firstTitle}</span>
                <span
                  className={`body_2 semi_bold me-1`}
                  style={{
                    color: RAFTaskTypeWithColor[taskRow.TaskType]?.Color,
                  }}
                >
                  {RAFTaskTypeWithColor[taskRow.TaskType]?.DisplayName}
                </span>
                {IsNotNullOrWhiteSpace(secondTitle) && (
                  <span className="me-1">{secondTitle}</span>
                )}
                <span className="dot_seprator me-1"></span>
                {!BrowserIsDevice && (
                  <span className="body_3 content_neutral_base me-1">
                    Updated:
                  </span>
                )}
                <span>
                  <div className="d-inline-block">
                    <RAFRecordInfo
                      createdBy={taskRow.CreatedBy}
                      createdDate={taskRow.CreatedDate}
                      modifiedDate={taskRow.ModifiedDate}
                      modifiedBy={taskRow.ModifiedBy}
                      lastActivityDate={taskRow.LastActivityDate}
                      dropdownBtnClassName="custom-button-sm p-0 remove-minWidth"
                      hideClockIcon
                      // {...(!BrowserIsDevice ? { content: "Updated: " } : {})}
                      spanContentClassName="body_3 content_neutral_dark medium text-decoration-underline"
                    // spanContentClassName="content_neutral_base recordInfo_text fw-bold"
                    ></RAFRecordInfo>
                  </div>
                </span>
                {false && (
                  <RAFDetailsValueWithSeparator outerClassName="h-auto raf_md">
                    <div className="row g-1 align-items-baseline">
                      <div className="col-auto">
                        <div className=""> {firstTitle}</div>
                      </div>
                      {/* <div className="col-auto">created</div> */}
                      <div className="col-auto">
                        <div
                          className={`body_2 semi_bold`}
                          style={{
                            color:
                              RAFTaskTypeWithColor[taskRow.TaskType]?.Color,
                          }}
                        >
                          {RAFTaskTypeWithColor[taskRow.TaskType]?.DisplayName}
                        </div>
                      </div>
                      {IsNotNullOrWhiteSpace(secondTitle) && (
                        <div className="col-auto">
                          <span className="ps-1">{secondTitle}</span>
                        </div>
                      )}
                      {/* {IsNotNullOrWhiteSpace(assigneeName) ? (
                    <div className="col-auto">
                      to
                      <span className="ps-1">{assigneeName}</span>
                    </div>
                  ) : (
                    ""
                  )} */}
                    </div>
                    <div className="row gx-1 align-items-baseline">
                      {!BrowserIsDevice && (
                        <div className="col-auto">
                          <span className="body_3 content_neutral_base">
                            Updated:
                          </span>
                        </div>
                      )}
                      <div className="col-auto">
                        <RAFRecordInfo
                          createdBy={taskRow.CreatedBy}
                          createdDate={taskRow.CreatedDate}
                          modifiedDate={taskRow.ModifiedDate}
                          modifiedBy={taskRow.ModifiedBy}
                          lastActivityDate={taskRow.LastActivityDate}
                          dropdownBtnClassName="custom-button-sm p-0 remove-minWidth"
                          hideClockIcon
                          // {...(!BrowserIsDevice ? { content: "Updated: " } : {})}
                          spanContentClassName="body_3 content_neutral_dark medium text-decoration-underline"
                        // spanContentClassName="content_neutral_base recordInfo_text fw-bold"
                        ></RAFRecordInfo>
                      </div>
                    </div>
                  </RAFDetailsValueWithSeparator>
                )}
              </div>
            );

            bodyTemplate = (
              <div className="row gx-2 align-items-center">
                <div className="col-auto">
                  {taskRow.TaskStatus === RAFActionStatus.Completed ? (
                    <span
                      className={`raf_badge ${taskRow.TaskType ===
                        RAFTaskTypeWithColor["To-Do"].DisplayName
                        ? `raf_badge_success`
                        : `surface_neutral_light`
                        }`}
                    >
                      {taskDate === "Unplanned" &&
                        taskRow.TaskType !==
                        RAFTaskTypeWithColor["To-Do"].DisplayName
                        ? "Not set"
                        : taskDate}
                    </span>
                  ) : (
                    <span
                      className={`raf_badge ${dateBadge} ${taskRow.TaskType ===
                        RAFTaskTypeWithColor["To-Do"].DisplayName
                        ? `surface_${todoTaskColor}${taskDate === "Unplanned" ? "_light" : "_base"
                        }`
                        : `surface_neutral_light`
                        }`}
                    >
                      {taskDate === "Unplanned" &&
                        taskRow.TaskType !==
                        RAFTaskTypeWithColor["To-Do"].DisplayName
                        ? "Not set"
                        : taskDate}
                    </span>
                  )}
                  {/* <div className="planner_timeline_date_card">
                        <div
                          className={`planner_timeline_date_card_month surface_${todoTaskColor}_semi_dark`}
                        >
                          {getFormatedDate(taskRow.TaskDate, "MMM")}
                        </div>
                        <div className="planner_timeline_date_card_date">
                          {getFormatedDate(taskRow.TaskDate, "DD")}
                        </div>
                      </div> */}
                </div>
                <div className="col">
                  <div
                    className="subtitle_2 content_neutral_dark ecllipseFirstLine"
                    {...(taskRow.TaskStatus === RAFActionStatus.Completed
                      ? { style: { textDecoration: "line-through" } }
                      : {})}
                  >
                    {taskRow.Title}
                  </div>
                </div>
                {IsNotNullOrWhiteSpace(taskRow.Description) && (
                  <div className="col-12">
                    <div className="mt-2 p-2 py-1 surface_neutral_light">
                      {descriptionContent}
                    </div>
                  </div>
                )}
              </div>
            );
          } else if (
            taskRow.TaskType === RAFTaskTypeWithColor["Action"].DisplayName
          ) {
            let firstTitle = "";
            let secondTitle = "";
            let firstUser = "";
            let secondUser = "";

            if (isNullOrUndefined(taskRow.AssigneeUID)) {
              if (isNotNullAndUndefined(taskRow.AssignTeamUID)) {
                if (
                  taskRow.CreatedByUID === msalInstance.currentUserId &&
                  taskRow.TaskStatus !== RAFActionStatus.Completed
                ) {
                  firstUser = "You ";
                  firstTitle = "have assigned an ";
                  secondTitle = " to ";
                  secondUser = taskRow.AssignTeam;
                } else if (
                  taskRow.CreatedByUID !== msalInstance.currentUserId &&
                  taskRow.TaskStatus !== RAFActionStatus.Completed
                ) {
                  firstUser = taskRow.CreatedBy;
                  firstTitle = " assigned an ";
                  secondTitle = " to ";
                  secondUser = taskRow.AssignTeam;
                } else if (
                  taskRow.CreatedByUID === msalInstance.currentUserId &&
                  taskRow.TaskStatus === RAFActionStatus.Completed
                ) {
                  if (taskRow.CompletedByUID === msalInstance.currentUserId) {
                    firstUser = "You ";
                    firstTitle = "completed the ";
                  } else {
                    firstUser = taskRow.CompletedBy;
                    firstTitle = " completed the ";
                    secondTitle = " assigned by you";
                  }
                } else if (
                  taskRow.CreatedByUID !== msalInstance.currentUserId &&
                  taskRow.TaskStatus === RAFActionStatus.Completed
                ) {
                  if (taskRow.CompletedByUID === taskRow.CreatedByUID) {
                    firstUser = taskRow.CompletedBy;
                    firstTitle = " completed the ";
                  } else {
                    firstUser = taskRow.CompletedBy;
                    firstTitle = taskRow.CompletedBy + " completed the ";
                    secondTitle = " assigned by ";
                    secondUser = taskRow.CreatedBy;
                  }
                }
              } else {
                if (
                  taskRow.CompletedByUID === msalInstance.currentUserId &&
                  taskRow.CreatedByUID === msalInstance.currentUserId &&
                  taskRow.TaskStatus === RAFActionStatus.Completed
                ) {
                  firstUser = "You ";
                  firstTitle = "completed the ";
                } else if (
                  taskRow.CompletedByUID !== msalInstance.currentUserId &&
                  taskRow.CreatedByUID !== msalInstance.currentUserId &&
                  taskRow.CompletedByUID !== taskRow.CreatedByUID &&
                  taskRow.TaskStatus === RAFActionStatus.Completed
                ) {
                  firstUser = taskRow.CompletedBy;
                  firstTitle = " completed the ";
                  secondTitle = " assigned by ";
                  secondUser = taskRow.CreatedBy;
                } else if (
                  taskRow.CompletedByUID !== msalInstance.currentUserId &&
                  taskRow.CreatedByUID !== msalInstance.currentUserId &&
                  taskRow.CompletedByUID === taskRow.CreatedByUID &&
                  taskRow.TaskStatus === RAFActionStatus.Completed
                ) {
                  firstUser = taskRow.CompletedBy;
                  firstTitle = " completed the ";
                } else if (
                  taskRow.CreatedByUID === msalInstance.currentUserId &&
                  taskRow.TaskStatus === RAFActionStatus.Unassigned
                ) {
                  firstTitle = "Unassigned ";
                  secondTitle = " planned by ";
                } else if (
                  taskRow.CreatedByUID !== msalInstance.currentUserId &&
                  taskRow.TaskStatus === RAFActionStatus.Unassigned
                ) {
                  firstTitle = "Unassigned ";
                  secondTitle = " planned by ";
                  secondUser = taskRow.CreatedBy;
                }
              }
            } else if (isNotNullAndUndefined(taskRow.AssigneeUID)) {
              if (
                taskRow.CreatedBy !== msalInstance.currentUserName &&
                taskRow.CreatedByUID !== msalInstance.currentUserId &&
                taskRow.AssigneeUID !== msalInstance.currentUserId &&
                taskRow.TaskStatus !== RAFActionStatus.Completed
              ) {
                if (taskRow.CreatedByUID === taskRow.AssigneeUID) {
                  firstUser = taskRow.Assignee;
                  firstTitle = " planned an ";
                } else {
                  firstUser = taskRow.CreatedBy;
                  firstTitle = " assigned an ";
                  secondTitle = " to ";
                  secondUser = taskRow.Assignee;
                }
              } else if (
                taskRow.CreatedBy !== msalInstance.currentUserName &&
                taskRow.CreatedByUID !== msalInstance.currentUserId &&
                taskRow.AssigneeUID !== msalInstance.currentUserId &&
                taskRow.TaskStatus === RAFActionStatus.Completed
              ) {
                if (taskRow.CreatedByUID === taskRow.AssigneeUID) {
                  firstUser = taskRow.Assignee;
                  firstTitle = " completed the ";
                } else {
                  firstUser = taskRow.Assignee;
                  firstTitle = " completed the ";
                  secondTitle = " assigned by ";
                  secondUser = taskRow.CreatedBy;
                }
              } else if (
                taskRow.CreatedBy === msalInstance.currentUserName &&
                taskRow.CreatedByUID === msalInstance.currentUserId &&
                taskRow.AssigneeUID === msalInstance.currentUserId &&
                taskRow.TaskStatus !== RAFActionStatus.Completed
              ) {
                firstUser = "You ";
                firstTitle = "have planned an ";
              } else if (
                taskRow.CreatedBy === msalInstance.currentUserName &&
                taskRow.CreatedByUID === msalInstance.currentUserId &&
                taskRow.AssigneeUID !== msalInstance.currentUserId &&
                taskRow.TaskStatus !== RAFActionStatus.Completed
              ) {
                firstUser = "You ";
                firstTitle = " have assigned an ";
                secondTitle = " to ";
                secondUser = taskRow.Assignee;
              } else if (
                taskRow.CreatedBy !== msalInstance.currentUserName &&
                taskRow.CreatedByUID !== msalInstance.currentUserId &&
                taskRow.AssigneeUID === msalInstance.currentUserId &&
                taskRow.TaskStatus !== RAFActionStatus.Completed
              ) {
                firstUser = taskRow.CreatedBy;
                firstTitle = " assigned an ";
              } else if (
                taskRow.CreatedBy !== msalInstance.currentUserName &&
                taskRow.CreatedByUID !== msalInstance.currentUserId &&
                taskRow.AssigneeUID !== msalInstance.currentUserId &&
                taskRow.TaskStatus !== RAFActionStatus.Completed
              ) {
                firstUser = taskRow.Assignee;
                firstTitle = " planned an ";
              } else if (
                taskRow.CreatedBy === msalInstance.currentUserName &&
                taskRow.CreatedByUID === msalInstance.currentUserId &&
                taskRow.AssigneeUID === msalInstance.currentUserId &&
                taskRow.TaskStatus === RAFActionStatus.Completed
              ) {
                firstUser = "You ";
                firstTitle = "completed the ";
              } else if (
                taskRow.CreatedBy === msalInstance.currentUserName &&
                taskRow.CreatedByUID === msalInstance.currentUserId &&
                taskRow.AssigneeUID !== msalInstance.currentUserId &&
                taskRow.TaskStatus === RAFActionStatus.Completed
              ) {
                firstUser = taskRow.Assignee;
                firstTitle = " completed the  ";
                secondTitle = " assigned by you";
              } else if (
                taskRow.CreatedBy !== msalInstance.currentUserName &&
                taskRow.CreatedByUID !== msalInstance.currentUserId &&
                taskRow.AssigneeUID !== msalInstance.currentUserId &&
                taskRow.TaskStatus === RAFActionStatus.Completed
              ) {
                firstUser = taskRow.Assignee;
                firstTitle = " completed the ";
              } else if (
                taskRow.CreatedBy !== msalInstance.currentUserName &&
                taskRow.CreatedByUID !== msalInstance.currentUserId &&
                taskRow.AssigneeUID === msalInstance.currentUserId &&
                taskRow.TaskStatus === RAFActionStatus.Completed
              ) {
                firstUser = "You ";
                firstTitle = "completed the ";
                secondTitle = " assigned by " + taskRow.CreatedBy;
                secondUser = taskRow.CreatedBy;
              }
            }

            headerContent = (
              <div className="body_2_light">
                {IsNotNullOrWhiteSpace(firstUser) && (
                  <span className="body_2">{firstUser}</span>
                )}
                <span className="">{firstTitle}</span>
                <span
                  className={`body_2 semi_bold me-1`}
                  style={{
                    color: RAFTaskTypeWithColor[taskRow.TaskType]?.Color,
                  }}
                >
                  {RAFTaskTypeWithColor[taskRow.TaskType]?.DisplayName}
                </span>
                {IsNotNullOrWhiteSpace(taskRow.SubType) && (
                  <>
                    <span className="me-1">-</span>
                    <div className="d-inline-block me-1">
                      <RAFDetailFieldCustom
                        displayValue={taskRow.SubType.substring(0, 4)}
                        value={taskRow.SubType}
                        title="SubType"
                        moduleName={RAFEntityName.Task}
                        isColorOption
                        field="SubType"
                        mode="textOnly"
                        showLabel={false}
                        substring={4}
                      />
                    </div>
                  </>
                )}
                {IsNotNullOrWhiteSpace(secondTitle) && (
                  <span className="me-1">{secondTitle}</span>
                )}
                {IsNotNullOrWhiteSpace(secondUser) && (
                  <span className="body_2">{secondUser}</span>
                )}
                {relatedTitle}
                <span className="dot_seprator mx-1"></span>
                {!BrowserIsDevice && (
                  <span className="body_3 content_neutral_base me-1">
                    Updated:
                  </span>
                )}
                <span>
                  <div className="d-inline-block">
                    <RAFRecordInfo
                      createdBy={taskRow.CreatedBy}
                      createdDate={taskRow.CreatedDate}
                      modifiedDate={taskRow.ModifiedDate}
                      modifiedBy={taskRow.ModifiedBy}
                      lastActivityDate={taskRow.LastActivityDate}
                      dropdownBtnClassName="custom-button-sm p-0 remove-minWidth"
                      hideClockIcon
                      // {...(!BrowserIsDevice ? { content: "Updated: " } : {})}
                      spanContentClassName="body_3 content_neutral_dark medium text-decoration-underline"
                    // spanContentClassName="content_neutral_base recordInfo_text fw-bold"
                    ></RAFRecordInfo>
                  </div>
                </span>
                {false && (
                  <RAFDetailsValueWithSeparator outerClassName="h-auto raf_md">
                    <div className="row g-1 align-items-baseline">
                      <div className="col-auto">
                        <div className=""> {firstTitle}</div>
                      </div>
                      {/* <div className="col-auto">created</div> */}
                      <div className="col-auto">
                        <div
                          className={`body_2 semi_bold`}
                          style={{
                            color:
                              RAFTaskTypeWithColor[taskRow.TaskType]?.Color,
                          }}
                        >
                          {RAFTaskTypeWithColor[taskRow.TaskType]?.DisplayName}
                        </div>
                      </div>
                      {IsNotNullOrWhiteSpace(secondTitle) && (
                        <div className="col-auto">
                          <span className="ps-1">{secondTitle}</span>
                        </div>
                      )}
                      {/* {IsNotNullOrWhiteSpace(assigneeName) ? (
                    <div className="col-auto">
                      to
                      <span className="ps-1">{assigneeName}</span>
                    </div>
                  ) : (
                    ""
                  )} */}
                    </div>
                    <div className="row gx-1 align-items-baseline">
                      {!BrowserIsDevice && (
                        <div className="col-auto">
                          <span className="body_3 content_neutral_base">
                            Updated:
                          </span>
                        </div>
                      )}
                      <div className="col-auto">
                        <RAFRecordInfo
                          createdBy={taskRow.CreatedBy}
                          createdDate={taskRow.CreatedDate}
                          modifiedDate={taskRow.ModifiedDate}
                          modifiedBy={taskRow.ModifiedBy}
                          lastActivityDate={taskRow.LastActivityDate}
                          dropdownBtnClassName="custom-button-sm p-0 remove-minWidth"
                          hideClockIcon
                          // {...(!BrowserIsDevice ? { content: "Updated: " } : {})}
                          spanContentClassName="body_3 content_neutral_dark medium text-decoration-underline"
                        // spanContentClassName="content_neutral_base recordInfo_text fw-bold"
                        ></RAFRecordInfo>
                      </div>
                    </div>
                  </RAFDetailsValueWithSeparator>
                )}
              </div>
            );

            bodyTemplate = (
              <div className="row gx-2 align-items-center">
                <div className="col-auto">
                  {taskRow.TaskStatus === RAFActionStatus.Completed ? (
                    <span
                      className={`raf_badge ${taskRow.TaskType ===
                        RAFTaskTypeWithColor["Action"].DisplayName
                        ? `raf_badge_success`
                        : `surface_neutral_light`
                        }`}
                    >
                      {taskDate === "Unplanned" &&
                        taskRow.TaskType !==
                        RAFTaskTypeWithColor["Action"].DisplayName
                        ? "Not set"
                        : taskDate}
                    </span>
                  ) : (
                    <span
                      className={`raf_badge ${dateBadge} ${taskRow.TaskType ===
                        RAFTaskTypeWithColor["Action"].DisplayName
                        ? `surface_${todoTaskColor}${taskDate === "Unplanned" ? "_light" : "_base"
                        }`
                        : `surface_neutral_light`
                        }`}
                    >
                      {taskDate === "Unplanned" &&
                        taskRow.TaskType !==
                        RAFTaskTypeWithColor["Action"].DisplayName
                        ? "Not set"
                        : taskDate}
                    </span>
                  )}
                  {/* <div className="planner_timeline_date_card">
                        <div
                          className={`planner_timeline_date_card_month surface_${todoTaskColor}_semi_dark`}
                        >
                          {getFormatedDate(taskRow.TaskDate, "MMM")}
                        </div>
                        <div className="planner_timeline_date_card_date">
                          {getFormatedDate(taskRow.TaskDate, "DD")}
                        </div>
                      </div> */}
                </div>
                <div className="col">
                  <div
                    className="subtitle_2 content_neutral_dark ecllipseFirstLine"
                    {...(taskRow.TaskStatus === RAFActionStatus.Completed
                      ? { style: { textDecoration: "line-through" } }
                      : {})}
                  >
                    {taskRow.Title}
                  </div>
                </div>
                {IsNotNullOrWhiteSpace(taskRow.Description) && (
                  <div className="col-12">
                    <div className="mt-2 p-2 py-1 surface_neutral_light">
                      {descriptionContent}
                    </div>
                  </div>
                )}
              </div>
            );
          } else if (
            taskRow.TaskType === RAFTaskTypeWithColor["Appointment"].DisplayName
          ) {
            let firstTitle = "";
            let secondTitle = "";
            let firstUser = "";
            let secondUser = "";

            if (isNullOrUndefined(taskRow.AssigneeUID)) {
              if (isNotNullAndUndefined(taskRow.AssignTeamUID)) {
                if (
                  taskRow.CreatedByUID === msalInstance.currentUserId &&
                  taskRow.TaskStatus !== RAFActionStatus.Completed
                ) {
                  firstUser = "You ";
                  firstTitle = "scheduled an ";
                  secondTitle = " to ";
                  secondUser = taskRow.AssignTeam;
                } else if (
                  taskRow.CreatedByUID !== msalInstance.currentUserId &&
                  taskRow.TaskStatus !== RAFActionStatus.Completed
                ) {
                  firstUser = taskRow.CreatedBy;
                  firstTitle = " scheduled an ";
                  secondTitle = " to ";
                  secondUser = taskRow.AssignTeam;
                } else if (
                  taskRow.CreatedByUID === msalInstance.currentUserId &&
                  taskRow.TaskStatus === RAFActionStatus.Completed
                ) {
                  if (taskRow.CompletedByUID === msalInstance.currentUserId) {
                    firstUser = "You ";
                    firstTitle = "completed the ";
                  } else {
                    firstUser = taskRow.CompletedBy;
                    firstTitle = " completed the ";
                    secondTitle = " scheduled by you";
                  }
                } else if (
                  taskRow.CreatedByUID !== msalInstance.currentUserId &&
                  taskRow.TaskStatus === RAFActionStatus.Completed
                ) {
                  if (taskRow.CompletedByUID === taskRow.CreatedByUID) {
                    firstUser = taskRow.CompletedBy;
                    firstTitle = " completed the ";
                  } else {
                    firstUser = taskRow.CompletedBy;
                    firstTitle = " completed the ";
                    secondTitle = " scheduled by ";
                    secondUser = taskRow.CreatedBy;
                  }
                }
              } else {
                if (
                  taskRow.CompletedByUID === msalInstance.currentUserId &&
                  taskRow.CreatedByUID === msalInstance.currentUserId &&
                  taskRow.TaskStatus === RAFActionStatus.Completed
                ) {
                  firstUser = "You ";
                  firstTitle = "completed the ";
                } else if (
                  taskRow.CompletedByUID !== msalInstance.currentUserId &&
                  taskRow.CreatedByUID !== msalInstance.currentUserId &&
                  taskRow.CompletedByUID !== taskRow.CreatedByUID &&
                  taskRow.TaskStatus === RAFActionStatus.Completed
                ) {
                  firstUser = taskRow.CompletedBy;
                  firstTitle = " completed the ";
                  secondTitle = " scheduled by ";
                  secondUser = taskRow.CreatedBy;
                } else if (
                  taskRow.CompletedByUID !== msalInstance.currentUserId &&
                  taskRow.CreatedByUID !== msalInstance.currentUserId &&
                  taskRow.CompletedByUID === taskRow.CreatedByUID &&
                  taskRow.TaskStatus === RAFActionStatus.Completed
                ) {
                  firstUser = taskRow.CompletedBy;
                  firstTitle = " completed the ";
                } else if (
                  taskRow.CreatedByUID === msalInstance.currentUserId &&
                  taskRow.TaskStatus === RAFActionStatus.Unassigned
                ) {
                  firstTitle = "Unassigned ";
                  secondTitle = " scheduled by you";
                } else if (
                  taskRow.CreatedByUID !== msalInstance.currentUserId &&
                  taskRow.TaskStatus === RAFActionStatus.Unassigned
                ) {
                  firstTitle = "Unassigned ";
                  secondTitle = " scheduled by ";
                  secondUser = taskRow.CreatedBy;
                }
              }
            } else if (isNotNullAndUndefined(taskRow.AssigneeUID)) {
              if (
                taskRow.CreatedBy !== msalInstance.currentUserName &&
                taskRow.CreatedByUID !== msalInstance.currentUserId &&
                taskRow.AssigneeUID !== msalInstance.currentUserId &&
                taskRow.TaskStatus !== RAFActionStatus.Completed
              ) {
                if (taskRow.CreatedByUID === taskRow.AssigneeUID) {
                  firstUser = taskRow.Assignee;
                  firstTitle = " scheduled an ";
                } else {
                  firstUser = taskRow.CreatedBy;
                  firstTitle = " scheduled an ";
                  secondTitle = " to ";
                  secondUser = taskRow.Assignee;
                }
              } else if (
                taskRow.CreatedBy !== msalInstance.currentUserName &&
                taskRow.CreatedByUID !== msalInstance.currentUserId &&
                taskRow.AssigneeUID !== msalInstance.currentUserId &&
                taskRow.TaskStatus === RAFActionStatus.Completed
              ) {
                if (taskRow.CreatedByUID === taskRow.AssigneeUID) {
                  firstUser = taskRow.Assignee;
                  firstTitle = " completed the ";
                } else {
                  firstUser = taskRow.Assignee;
                  firstTitle = " completed the ";
                  secondTitle = " scheduled by ";
                  secondUser = taskRow.CreatedBy;
                }
              } else if (
                taskRow.CreatedBy === msalInstance.currentUserName &&
                taskRow.CreatedByUID === msalInstance.currentUserId &&
                taskRow.AssigneeUID === msalInstance.currentUserId &&
                taskRow.TaskStatus !== RAFActionStatus.Completed
              ) {
                firstUser = "You ";
                firstTitle = "scheduled an ";
              } else if (
                taskRow.CreatedBy === msalInstance.currentUserName &&
                taskRow.CreatedByUID === msalInstance.currentUserId &&
                taskRow.AssigneeUID !== msalInstance.currentUserId &&
                taskRow.TaskStatus !== RAFActionStatus.Completed
              ) {
                firstUser = "You ";
                firstTitle = "scheduled an ";
                secondTitle = " to ";
                secondUser = taskRow.Assignee;
              } else if (
                taskRow.CreatedBy !== msalInstance.currentUserName &&
                taskRow.CreatedByUID !== msalInstance.currentUserId &&
                taskRow.AssigneeUID === msalInstance.currentUserId &&
                taskRow.TaskStatus !== RAFActionStatus.Completed
              ) {
                firstUser = taskRow.CreatedBy;
                firstTitle = " scheduled an ";
              } else if (
                taskRow.CreatedBy !== msalInstance.currentUserName &&
                taskRow.CreatedByUID !== msalInstance.currentUserId &&
                taskRow.AssigneeUID !== msalInstance.currentUserId &&
                taskRow.TaskStatus !== RAFActionStatus.Completed
              ) {
                firstUser = taskRow.Assignee;
                firstTitle = " scheduled an ";
              } else if (
                taskRow.CreatedBy !== msalInstance.currentUserName &&
                taskRow.CreatedByUID !== msalInstance.currentUserId &&
                taskRow.AssigneeUID !== msalInstance.currentUserId &&
                taskRow.AssigneeUID === taskRow.CreatedByUID &&
                taskRow.TaskStatus !== RAFActionStatus.Completed
              ) {
                firstUser = taskRow.Assignee;
                firstTitle = " scheduled an ";
              } else if (
                taskRow.CreatedBy !== msalInstance.currentUserName &&
                taskRow.CreatedByUID !== msalInstance.currentUserId &&
                taskRow.AssigneeUID !== msalInstance.currentUserId &&
                taskRow.AssigneeUID !== taskRow.CreatedByUID &&
                taskRow.TaskStatus !== RAFActionStatus.Completed
              ) {
                firstUser = taskRow.CreatedBy;
                firstTitle = " scheduled an ";
                secondTitle = " to ";
                secondUser = taskRow.Assignee;
              } else if (
                taskRow.CreatedBy === msalInstance.currentUserName &&
                taskRow.CreatedByUID === msalInstance.currentUserId &&
                taskRow.AssigneeUID === msalInstance.currentUserId &&
                taskRow.TaskStatus === RAFActionStatus.Completed
              ) {
                firstUser = "You ";
                firstTitle = "completed the ";
              } else if (
                taskRow.CreatedBy === msalInstance.currentUserName &&
                taskRow.CreatedByUID === msalInstance.currentUserId &&
                taskRow.AssigneeUID !== msalInstance.currentUserId &&
                taskRow.TaskStatus === RAFActionStatus.Completed
              ) {
                firstUser = taskRow.Assignee;
                firstTitle = " completed the  ";
                secondTitle = " scheduled by you";
              } else if (
                taskRow.CreatedBy !== msalInstance.currentUserName &&
                taskRow.CreatedByUID !== msalInstance.currentUserId &&
                taskRow.AssigneeUID !== msalInstance.currentUserId &&
                taskRow.TaskStatus === RAFActionStatus.Completed
              ) {
                firstUser = taskRow.Assignee;
                firstTitle = " completed the ";
              } else if (
                taskRow.CreatedBy !== msalInstance.currentUserName &&
                taskRow.CreatedByUID !== msalInstance.currentUserId &&
                taskRow.AssigneeUID === msalInstance.currentUserId &&
                taskRow.TaskStatus === RAFActionStatus.Completed
              ) {
                firstUser = "You ";
                firstTitle = "completed the ";
                secondTitle = " scheduled by ";
                secondUser = taskRow.CreatedBy;
              }
            }

            headerContent = (
              <div className="body_2_light">
                {IsNotNullOrWhiteSpace(firstUser) && (
                  <span className="body_2">{firstUser}</span>
                )}
                {IsNotNullOrWhiteSpace(firstTitle) && (
                  <span className="">{firstTitle}</span>
                )}
                <span
                  className={`body_2 semi_bold me-1`}
                  style={{
                    color: RAFTaskTypeWithColor[taskRow.TaskType]?.Color,
                  }}
                >
                  {RAFTaskTypeWithColor[taskRow.TaskType]?.DisplayName}
                </span>
                {IsNotNullOrWhiteSpace(secondTitle) && (
                  <span className="me-1">{secondTitle}</span>
                )}
                {IsNotNullOrWhiteSpace(secondUser) && (
                  <span className="body_2">{secondUser}</span>
                )}
                {relatedTitle}
                <span className="dot_seprator mx-1"></span>
                {!BrowserIsDevice && (
                  <span className="body_3 content_neutral_base me-1">
                    Updated:
                  </span>
                )}
                <span>
                  <div className="d-inline-block">
                    <RAFRecordInfo
                      createdBy={taskRow.CreatedBy}
                      createdDate={taskRow.CreatedDate}
                      modifiedDate={taskRow.ModifiedDate}
                      modifiedBy={taskRow.ModifiedBy}
                      lastActivityDate={taskRow.LastActivityDate}
                      dropdownBtnClassName="custom-button-sm p-0 remove-minWidth"
                      hideClockIcon
                      // {...(!BrowserIsDevice ? { content: "Updated: " } : {})}
                      spanContentClassName="body_3 content_neutral_dark medium text-decoration-underline"
                    // spanContentClassName="content_neutral_base recordInfo_text fw-bold"
                    ></RAFRecordInfo>
                  </div>
                </span>
                {false && (
                  <RAFDetailsValueWithSeparator outerClassName="h-auto raf_md">
                    <div className="row g-1 align-items-baseline">
                      <div className="col-auto">
                        <div className=""> {firstTitle}</div>
                      </div>
                      {/* <div className="col-auto">created</div> */}
                      <div className="col-auto">
                        <div
                          className={`body_2 semi_bold`}
                          style={{
                            color:
                              RAFTaskTypeWithColor[taskRow.TaskType]?.Color,
                          }}
                        >
                          {RAFTaskTypeWithColor[taskRow.TaskType]?.DisplayName}
                        </div>
                      </div>
                      {IsNotNullOrWhiteSpace(secondTitle) && (
                        <div className="col-auto">
                          <span className="ps-1">{secondTitle}</span>
                        </div>
                      )}
                      {/* {IsNotNullOrWhiteSpace(assigneeName) ? (
                    <div className="col-auto">
                      to
                      <span className="ps-1">{assigneeName}</span>
                    </div>
                  ) : (
                    ""
                  )} */}
                    </div>
                    <div className="row gx-1 align-items-baseline">
                      {!BrowserIsDevice && (
                        <div className="col-auto">
                          <span className="body_3 content_neutral_base">
                            Updated:
                          </span>
                        </div>
                      )}
                      <div className="col-auto">
                        <RAFRecordInfo
                          createdBy={taskRow.CreatedBy}
                          createdDate={taskRow.CreatedDate}
                          modifiedDate={taskRow.ModifiedDate}
                          modifiedBy={taskRow.ModifiedBy}
                          lastActivityDate={taskRow.LastActivityDate}
                          dropdownBtnClassName="custom-button-sm p-0 remove-minWidth"
                          hideClockIcon
                          // {...(!BrowserIsDevice ? { content: "Updated: " } : {})}
                          spanContentClassName="body_3 content_neutral_dark medium text-decoration-underline"
                        // spanContentClassName="content_neutral_base recordInfo_text fw-bold"
                        ></RAFRecordInfo>
                      </div>
                    </div>
                  </RAFDetailsValueWithSeparator>
                )}
              </div>
            );

            bodyTemplate = (
              <div className="row gx-2 align-items-center">
                <div className="col-auto">
                  {taskRow.TaskStatus === RAFActionStatus.Completed ? (
                    <span
                      className={`raf_badge ${taskRow.TaskType ===
                        RAFTaskTypeWithColor["Appointment"].DisplayName
                        ? `raf_badge_success`
                        : `surface_neutral_light`
                        }`}
                    >
                      {taskDate === "Unplanned" &&
                        taskRow.TaskType !==
                        RAFTaskTypeWithColor["Appointment"].DisplayName
                        ? "Not set"
                        : taskDate}
                    </span>
                  ) : (
                    <span
                      className={`raf_badge ${dateBadge} ${taskRow.TaskType ===
                        RAFTaskTypeWithColor["Appointment"].DisplayName
                        ? `surface_${todoTaskColor}${taskDate === "Unplanned" ? "_light" : "_base"
                        }`
                        : `surface_neutral_light`
                        }`}
                    >
                      {taskDate === "Unplanned" &&
                        taskRow.TaskType !==
                        RAFTaskTypeWithColor["Appointment"].DisplayName
                        ? "Not set"
                        : taskDate}
                    </span>
                  )}
                  {/* <div className="planner_timeline_date_card">
                        <div
                          className={`planner_timeline_date_card_month surface_${todoTaskColor}_semi_dark`}
                        >
                          {getFormatedDate(taskRow.TaskDate, "MMM")}
                        </div>
                        <div className="planner_timeline_date_card_date">
                          {getFormatedDate(taskRow.TaskDate, "DD")}
                        </div>
                      </div> */}
                </div>
                <div className="col">
                  <div
                    className="subtitle_2 content_neutral_dark ecllipseFirstLine"
                    {...(taskRow.TaskStatus === RAFActionStatus.Completed
                      ? { style: { textDecoration: "line-through" } }
                      : {})}
                  >
                    {taskRow.Title}
                  </div>
                </div>
                {IsNotNullOrWhiteSpace(taskRow.Description) && (
                  <div className="col-12">
                    <div className="mt-2 p-2 py-1 surface_neutral_light">
                      {descriptionContent}
                    </div>
                  </div>
                )}
              </div>
            );
          } else if (
            taskRow.TaskType === RAFTaskTypeWithColor["Note"].DisplayName
          ) {
            let firstTitle = "";
            let secondTitle = "";
            let firstUser = "";
            if (
              taskRow.CreatedBy === msalInstance.currentUserName &&
              taskRow.CreatedByUID === msalInstance.currentUserId &&
              taskRow.AssigneeUID === msalInstance.currentUserId
            ) {
              firstUser = "You ";
              firstTitle = "added a ";
            } else if (
              taskRow.CreatedBy !== msalInstance.currentUserName &&
              taskRow.CreatedByUID !== msalInstance.currentUserId &&
              taskRow.AssigneeUID !== msalInstance.currentUserId
            ) {
              firstUser = taskRow.CreatedBy;
              firstTitle = " added a ";
            }

            headerContent = (
              <div className="body_2_light">
                {IsNotNullOrWhiteSpace(firstUser) && (
                  <span className="body_2">{firstUser}</span>
                )}
                {IsNotNullOrWhiteSpace(firstUser) && (
                  <span className="">{firstTitle}</span>
                )}
                <span
                  className={`body_2 semi_bold me-1`}
                  style={{
                    color: RAFTaskTypeWithColor[taskRow.TaskType]?.Color,
                  }}
                >
                  {RAFTaskTypeWithColor[taskRow.TaskType]?.DisplayName}
                </span>
                {IsNotNullOrWhiteSpace(secondTitle) && (
                  <span className="me-1">{secondTitle}</span>
                )}
                {relatedTitle}
                <span className="dot_seprator mx-1"></span>
                {!BrowserIsDevice && (
                  <span className="body_3 content_neutral_base me-1">
                    Updated:
                  </span>
                )}
                <span>
                  <div className="d-inline-block">
                    <RAFRecordInfo
                      createdBy={taskRow.CreatedBy}
                      createdDate={taskRow.CreatedDate}
                      modifiedDate={taskRow.ModifiedDate}
                      modifiedBy={taskRow.ModifiedBy}
                      lastActivityDate={taskRow.LastActivityDate}
                      dropdownBtnClassName="custom-button-sm p-0 remove-minWidth"
                      hideClockIcon
                      // {...(!BrowserIsDevice ? { content: "Updated: " } : {})}
                      spanContentClassName="body_3 content_neutral_dark medium text-decoration-underline"
                    // spanContentClassName="content_neutral_base recordInfo_text fw-bold"
                    ></RAFRecordInfo>
                  </div>
                </span>
                {false && (
                  <RAFDetailsValueWithSeparator outerClassName="h-auto raf_md">
                    <div className="row g-1 align-items-baseline">
                      <div className="col-auto">
                        <div className=""> {firstTitle}</div>
                      </div>
                      {/* <div className="col-auto">created</div> */}
                      <div className="col-auto">
                        <div
                          className={`body_2 semi_bold`}
                          style={{
                            color:
                              RAFTaskTypeWithColor[taskRow.TaskType]?.Color,
                          }}
                        >
                          {RAFTaskTypeWithColor[taskRow.TaskType]?.DisplayName}
                        </div>
                      </div>
                      {IsNotNullOrWhiteSpace(secondTitle) && (
                        <div className="col-auto">
                          <span className="ps-1">{secondTitle}</span>
                        </div>
                      )}
                      {/* {IsNotNullOrWhiteSpace(assigneeName) ? (
                    <div className="col-auto">
                      to
                      <span className="ps-1">{assigneeName}</span>
                    </div>
                  ) : (
                    ""
                  )} */}
                    </div>
                    <div className="row gx-1 align-items-baseline">
                      {!BrowserIsDevice && (
                        <div className="col-auto">
                          <span className="body_3 content_neutral_base">
                            Updated:
                          </span>
                        </div>
                      )}
                      <div className="col-auto">
                        <RAFRecordInfo
                          createdBy={taskRow.CreatedBy}
                          createdDate={taskRow.CreatedDate}
                          modifiedDate={taskRow.ModifiedDate}
                          modifiedBy={taskRow.ModifiedBy}
                          lastActivityDate={taskRow.LastActivityDate}
                          dropdownBtnClassName="custom-button-sm p-0 remove-minWidth"
                          hideClockIcon
                          // {...(!BrowserIsDevice ? { content: "Updated: " } : {})}
                          spanContentClassName="body_3 content_neutral_dark medium text-decoration-underline"
                        // spanContentClassName="content_neutral_base recordInfo_text fw-bold"
                        ></RAFRecordInfo>
                      </div>
                    </div>
                  </RAFDetailsValueWithSeparator>
                )}
              </div>
            );

            bodyTemplate = (
              <div className="row gx-2 align-items-center">
                {/* <div className="col-auto">
                  <span className={`raf_badge raf_badge_success`}>
                    {IsNotNullOrWhiteSpace(taskRow.TaskDate)
                      ? taskDate
                      : "Not set"}
                  </span>
                </div> */}
                <div className="col">
                  <div className="subtitle_2 content_neutral_dark ecllipseFirstLine">
                    {taskRow.Title}
                  </div>
                </div>
                {IsNotNullOrWhiteSpace(taskRow.Description) && (
                  <div className="col-12">
                    <div className="mt-2 p-2 py-1 surface_neutral_light">
                      {descriptionContent}
                    </div>
                  </div>
                )}
              </div>
            );
          } else if (
            taskRow.TaskType === RAFTaskTypeWithColor["Call"].DisplayName
          ) {
            let firstTitle = "";
            let secondTitle = "";
            let firstUser = "";
            if (
              taskRow.CreatedBy === msalInstance.currentUserName &&
              taskRow.CreatedByUID === msalInstance.currentUserId
            ) {
              if (taskRow.SubType === RAFTaskSubType.Inbound) {
                firstUser = "You ";
                firstTitle = "received a ";
              } else {
                firstUser = "You ";
                firstTitle = "made a ";
              }
            } else if (
              taskRow.CreatedBy !== msalInstance.currentUserName &&
              taskRow.CreatedByUID !== msalInstance.currentUserId
            ) {
              if (taskRow.SubType === RAFTaskSubType.Inbound) {
                firstUser = taskRow.CreatedBy;
                firstTitle = " received a ";
              } else {
                firstUser = taskRow.CreatedBy;
                firstTitle = " logged a ";
              }
            }

            headerContent = (
              <div className="body_2_light">
                {IsNotNullOrWhiteSpace(firstUser) && (
                  <span className="body_2">{firstUser}</span>
                )}
                {IsNotNullOrWhiteSpace(firstUser) && (
                  <span className="">{firstTitle}</span>
                )}
                <span
                  className={`body_2 semi_bold me-1`}
                  style={{
                    color: RAFTaskTypeWithColor[taskRow.TaskType]?.Color,
                  }}
                >
                  {RAFTaskTypeWithColor[taskRow.TaskType]?.DisplayName}
                </span>
                {IsNotNullOrWhiteSpace(secondTitle) && (
                  <span className="me-1">{secondTitle}</span>
                )}
                {relatedTitle}
                <span className="dot_seprator mx-1"></span>
                {!BrowserIsDevice && (
                  <span className="body_3 content_neutral_base me-1">
                    Updated:
                  </span>
                )}
                <span>
                  <div className="d-inline-block">
                    <RAFRecordInfo
                      createdBy={taskRow.CreatedBy}
                      createdDate={taskRow.CreatedDate}
                      modifiedDate={taskRow.ModifiedDate}
                      modifiedBy={taskRow.ModifiedBy}
                      lastActivityDate={taskRow.LastActivityDate}
                      dropdownBtnClassName="custom-button-sm p-0 remove-minWidth"
                      hideClockIcon
                      // {...(!BrowserIsDevice ? { content: "Updated: " } : {})}
                      spanContentClassName="body_3 content_neutral_dark medium text-decoration-underline"
                    // spanContentClassName="content_neutral_base recordInfo_text fw-bold"
                    ></RAFRecordInfo>
                  </div>
                </span>
                {false && (
                  <RAFDetailsValueWithSeparator outerClassName="h-auto raf_md">
                    <div className="row g-1 align-items-baseline">
                      <div className="col-auto">
                        <div className=""> {firstTitle}</div>
                      </div>
                      {/* <div className="col-auto">created</div> */}
                      <div className="col-auto">
                        <div
                          className={`body_2 semi_bold`}
                          style={{
                            color:
                              RAFTaskTypeWithColor[taskRow.TaskType]?.Color,
                          }}
                        >
                          {RAFTaskTypeWithColor[taskRow.TaskType]?.DisplayName}
                        </div>
                      </div>
                      {IsNotNullOrWhiteSpace(secondTitle) && (
                        <div className="col-auto">
                          <span className="ps-1">{secondTitle}</span>
                        </div>
                      )}
                    </div>
                    <div className="row gx-1 align-items-baseline">
                      {!BrowserIsDevice && (
                        <div className="col-auto">
                          <span className="body_3 content_neutral_base">
                            Updated:
                          </span>
                        </div>
                      )}
                      <div className="col-auto">
                        <RAFRecordInfo
                          createdBy={taskRow.CreatedBy}
                          createdDate={taskRow.CreatedDate}
                          modifiedDate={taskRow.ModifiedDate}
                          modifiedBy={taskRow.ModifiedBy}
                          lastActivityDate={taskRow.LastActivityDate}
                          dropdownBtnClassName="custom-button-sm p-0 remove-minWidth"
                          hideClockIcon
                          // {...(!BrowserIsDevice ? { content: "Updated: " } : {})}
                          spanContentClassName="body_3 content_neutral_dark medium text-decoration-underline"
                        ></RAFRecordInfo>
                      </div>
                    </div>
                  </RAFDetailsValueWithSeparator>
                )}
              </div>
            );

            bodyTemplate = (
              <div className="row gx-2 align-items-center">
                <div className="col-auto">
                  <span className={`raf_badge raf_badge_success`}>
                    {IsNotNullOrWhiteSpace(taskDate) ? taskDate : "Not set"}
                  </span>
                </div>
                <div className="col">
                  <div className="subtitle_2 content_neutral_dark ecllipseFirstLine">
                    {taskRow.Title}
                  </div>
                </div>
                {IsNotNullOrWhiteSpace(taskRow.Description) && (
                  <div className="col-12">
                    <div className="mt-2 p-2 py-1 surface_neutral_light">
                      {descriptionContent}
                    </div>
                  </div>
                )}
              </div>
            );
          } else if (
            taskRow.TaskType === RAFTaskTypeWithColor["Email"].DisplayName
          ) {
            let firstTitle = "";
            let secondTitle = "";
            let firstUser = "";
            if (
              taskRow.CreatedBy === msalInstance.currentUserName &&
              taskRow.CreatedByUID === msalInstance.currentUserId
            ) {
              if (taskRow.SubType === RAFTaskSubType.Received) {
                firstUser = "You ";
                firstTitle = "received an ";
              } else {
                firstUser = "You ";
                firstTitle = "sent an ";
              }
            } else if (
              taskRow.CreatedBy !== msalInstance.currentUserName &&
              taskRow.CreatedByUID !== msalInstance.currentUserId
            ) {
              if (taskRow.SubType === RAFTaskSubType.Received) {
                firstUser = taskRow.CreatedBy;
                firstTitle = " received an ";
              } else {
                firstUser = taskRow.CreatedBy;
                firstTitle = " sent an ";
              }
            }

            headerContent = (
              <div className="body_2_light">
                {IsNotNullOrWhiteSpace(firstUser) && (
                  <span className="body_2">{firstUser}</span>
                )}
                {IsNotNullOrWhiteSpace(firstUser) && (
                  <span className="">{firstTitle}</span>
                )}
                <span
                  className={`body_2 semi_bold me-1`}
                  style={{
                    color: RAFTaskTypeWithColor[taskRow.TaskType]?.Color,
                  }}
                >
                  {RAFTaskTypeWithColor[taskRow.TaskType]?.DisplayName}
                </span>
                {IsNotNullOrWhiteSpace(secondTitle) && (
                  <span className="me-1">{secondTitle}</span>
                )}
                {relatedTitle}
                <span className="dot_seprator mx-1"></span>
                {!BrowserIsDevice && (
                  <span className="body_3 content_neutral_base me-1">
                    Updated:
                  </span>
                )}
                <span>
                  <div className="d-inline-block">
                    <RAFRecordInfo
                      createdBy={taskRow.CreatedBy}
                      createdDate={taskRow.CreatedDate}
                      modifiedDate={taskRow.ModifiedDate}
                      modifiedBy={taskRow.ModifiedBy}
                      lastActivityDate={taskRow.LastActivityDate}
                      dropdownBtnClassName="custom-button-sm p-0 remove-minWidth"
                      hideClockIcon
                      // {...(!BrowserIsDevice ? { content: "Updated: " } : {})}
                      spanContentClassName="body_3 content_neutral_dark medium text-decoration-underline"
                    // spanContentClassName="content_neutral_base recordInfo_text fw-bold"
                    ></RAFRecordInfo>
                  </div>
                </span>
                {false && (
                  <RAFDetailsValueWithSeparator outerClassName="h-auto raf_md">
                    <div className="row g-1 align-items-baseline">
                      <div className="col-auto">
                        <div className=""> {firstTitle}</div>
                      </div>
                      {/* <div className="col-auto">created</div> */}
                      <div className="col-auto">
                        <div
                          className={`body_2 semi_bold`}
                          style={{
                            color:
                              RAFTaskTypeWithColor[taskRow.TaskType]?.Color,
                          }}
                        >
                          {RAFTaskTypeWithColor[taskRow.TaskType]?.DisplayName}
                        </div>
                      </div>
                      {IsNotNullOrWhiteSpace(secondTitle) && (
                        <div className="col-auto">
                          <span className="ps-1">{secondTitle}</span>
                        </div>
                      )}
                    </div>
                    <div className="row gx-1 align-items-baseline">
                      {!BrowserIsDevice && (
                        <div className="col-auto">
                          <span className="body_3 content_neutral_base">
                            Updated:
                          </span>
                        </div>
                      )}
                      <div className="col-auto">
                        <RAFRecordInfo
                          createdBy={taskRow.CreatedBy}
                          createdDate={taskRow.CreatedDate}
                          modifiedDate={taskRow.ModifiedDate}
                          modifiedBy={taskRow.ModifiedBy}
                          lastActivityDate={taskRow.LastActivityDate}
                          dropdownBtnClassName="custom-button-sm p-0 remove-minWidth"
                          hideClockIcon
                          // {...(!BrowserIsDevice ? { content: "Updated: " } : {})}
                          spanContentClassName="body_3 content_neutral_dark medium text-decoration-underline"
                        ></RAFRecordInfo>
                      </div>
                    </div>
                  </RAFDetailsValueWithSeparator>
                )}
              </div>
            );

            bodyTemplate = (
              <div className="row gx-2 align-items-center">
                <div className="col-auto">
                  <span className={`raf_badge raf_badge_success`}>
                    {IsNotNullOrWhiteSpace(taskDate) ? taskDate : "Not set"}
                  </span>
                </div>
                <div className="col">
                  <div className="subtitle_2 content_neutral_dark ecllipseFirstLine">
                    {taskRow.Title}
                  </div>
                </div>
                {IsNotNullOrWhiteSpace(taskRow.Description) && (
                  <div className="col-12">
                    <div className="mt-2 p-2 py-1 surface_neutral_light">
                      {descriptionContent}
                    </div>
                  </div>
                )}
              </div>
            );
          }

          let isCompleted =
            (taskRow.TaskStatus === RAFStatusNameWithColor.Done.DisplayName ||
              taskRow.TaskStatus ===
              RAFStatusNameWithColor.Completed.DisplayName) &&
            (taskRow.TaskType === RAFTaskTypeWithColor["To-Do"].Value ||
              taskRow.TaskType === RAFTaskTypeWithColor["Action"].Value ||
              taskRow.TaskType === RAFTaskTypeWithColor["Appointment"].Value ||
              taskRow.TaskType === RAFTaskTypeWithColor["Call"].Value ||
              taskRow.TaskType === RAFTaskTypeWithColor["Note"].Value);

          return (
            <li
              className={`timeline__li pointer${(taskRow.TaskStatus ===
                RAFStatusNameWithColor.Done.DisplayName ||
                taskRow.TaskStatus ===
                RAFStatusNameWithColor.Completed.DisplayName) &&
                (taskRow.TaskType === RAFTaskTypeWithColor["To-Do"].Value ||
                  taskRow.TaskType === RAFTaskTypeWithColor["Action"].Value ||
                  taskRow.TaskType ===
                  RAFTaskTypeWithColor["Appointment"].Value ||
                  taskRow.TaskType === RAFTaskTypeWithColor["Call"].Value ||
                  taskRow.TaskType === RAFTaskTypeWithColor["Note"].Value)
                ? " timeline__li__completed"
                : ""
                }`}
              key={taskRow.UID}
            >
              <div className={`timeline__item`}>
                <div className="row gx-md-3 gx-2">
                  <div className="col-auto">
                    <div
                      // className={`surface_${
                      //   IsNotNullOrWhiteSpace(
                      //     RAFTaskTypeWithColor[taskRow.TaskType]
                      //   )
                      //     ? RAFTaskTypeWithColor[taskRow.TaskType].className
                      //     : "neutral"
                      // }_light timeline__item__circle`}
                      className="timeline__item__circle"
                      style={{
                        borderColor:
                          RAFTaskTypeWithColor[taskRow.TaskType]?.Color,
                      }}
                    >
                      {isCompleted && (
                        <div className="timeline__item__circle__completed">
                          <i className="fas fa-check-circle"></i>
                        </div>
                      )}
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{
                          // stroke: isCompleted
                          //   ? "#fff"
                          //   : RAFTaskTypeWithColor[taskRow.TaskType]?.Color,
                          stroke: RAFTaskTypeWithColor[taskRow.TaskType]?.Color,
                        }}
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            RAFTaskTypeWithColor[taskRow.TaskType]?.svgContent
                          ),
                        }}
                      ></div>
                    </div>
                  </div>
                  {/* <div className="col-auto">
                    {(taskRow.TaskStatus ===
                      RAFStatusNameWithColor.Done.DisplayName ||
                      taskRow.TaskStatus ===
                        RAFStatusNameWithColor.Completed.DisplayName) &&
                    (taskRow.TaskType === RAFTaskTypeWithColor["To-Do"].Value ||
                      taskRow.TaskType ===
                        RAFTaskTypeWithColor["Action"].Value ||
                      taskRow.TaskType ===
                        RAFTaskTypeWithColor["Appointment"].Value ||
                      taskRow.TaskType === RAFTaskTypeWithColor["Call"].Value ||
                      taskRow.TaskType ===
                        RAFTaskTypeWithColor["Note"].Value) ? (
                      <div
                        className="timeline__item__circle"
                        style={{
                          borderColor: `var(--surface-state-success-base)`,
                        }}
                      >
                        <div
                          className="d-flex align-items-center justify-content-center"
                          style={{
                            stroke: "var(--content-neutral-light)",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path d="M13.3334 4L6.00002 11.3333L2.66669 8" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`
                            ),
                          }}
                        ></div>
                      </div>
                    ) : (
                      <div
                        className="timeline__item__circle"
                        style={{
                          borderColor:
                            RAFTaskTypeWithColor[taskRow.TaskType]?.Color,
                        }}
                      >
                        <div
                          className="d-flex align-items-center justify-content-center"
                          style={{
                            stroke:
                              RAFTaskTypeWithColor[taskRow.TaskType]?.Color,
                          }}
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              RAFTaskTypeWithColor[taskRow.TaskType]?.svgContent
                            ),
                          }}
                        ></div>
                      </div>
                    )}
                  </div> */}
                  <div className="col">
                    <div className="body_2 mt-2-5">{headerContent}</div>
                    <CustomCardWidget
                      // cardContentClassName="p-2"
                      cardClassName={`mt-2 surface_neutral_base note_card`}
                      onClick={() => props.rowClick(taskRow)}
                    >
                      {bodyTemplate}
                    </CustomCardWidget>
                  </div>
                </div>
              </div>
            </li>
          );
        })
      ) : (
        <div className="container-fluid px-0">
          <RAFDeletedRecordState
            title="No data available at the moment!"
            showBackBtn={false}
          />
        </div>
      )}
    </ul>
  );
}

export default React.memo(PlannerListTimelineContent);
