import { DialogComponent } from "@syncfusion/ej2-react-popups";
import DOMPurify from "dompurify";
import React, {
  Fragment,
  PropsWithChildren,
  Reducer,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { RolePermissionsContext } from "../../../../RAFAuthentication/RAFRolePermissionsContextProvider";
import { PreventFocusOnDialogOpen } from "../../../../RAFComponents/Dialog/SFDialogUtils";
import CustomCardWidget from "../../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDetailsValueWithSeparator from "../../../../RAFComponents/Navigation/RAFDetailsValueWithSeparator/RAFDetailsValueWithSeparator";
import RAFEmptyState from "../../../../RAFComponents/Navigation/RAFEmptyState";
import RAFRecordInfo from "../../../../RAFComponents/Navigation/RAFRecordInfo";
import RAFPermissionRender, {
  hasPermission,
} from "../../../../RAFComponents/helpers/PermissionHelper";
import {
  RAFEventName,
  subscribeRAFEvent,
  triggerRAFEvent,
  unsubscribeRAFEvent,
} from "../../../../RAFComponents/helpers/RAFDocumentEvents";
import {
  IsNotNullOrWhiteSpace,
  isNotEmptyArray,
  isNotNullAndUndefined,
  propertyOf,
} from "../../../../RAFComponents/helpers/utils";
import ACLoadingPanel from "../../../../components/shared/ACLoadingPanel";
import {
  BrowserIsDevice,
  RAFButtonConstant,
  RAFTaskType
} from "../../../../constants/Common/Constants";
import { RAFEntityName } from "../../../../constants/Common/EntityConstants";
import { PermissionConstants } from "../../../../constants/Common/PermissionConstants";
import ManageTaskActivity from "../../../ActiveContacts/Task/TaskActivity/ManageTaskActivity";
import { TaskRow } from "../../../ActiveContacts/Task/TaskRow";
import { getPlannerRelatedRecords } from "../PlannerTask/PlannerLogHelper";
import ClientNoteList from "./ClientNoteList";

interface IProps {
  relatedToType?: string;
  careRecipientUID?: string;
  careRecipientName?: string;

  viewMode?: "Horizontal" | "Vertical";

  paddingClassname?: string;
  viewAllClick?: () => void;
}

interface IState {
  isLoading: boolean;
  taskItemsRow: TaskRow[];
  showManageClientNoteContent: boolean;
  selectedObjectUID: string;

  showMoreClientNoteContent: boolean;
}

function ClientProfileNoteList({
  careRecipientUID,
  careRecipientName,
  paddingClassname = "p-2 p-md-3",
  ...props
}: PropsWithChildren<IProps>) {
  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      taskItemsRow: null,
      showManageClientNoteContent: false,
      showMoreClientNoteContent: false,
      selectedObjectUID: null,
    }
  );

  const rolePermissionsContext = useContext(RolePermissionsContext);
  const permissionValue = isNotNullAndUndefined(rolePermissionsContext)
    ? rolePermissionsContext.permissionValue
    : null;

  useEffect(() => {
    refresh();
  }, [careRecipientUID]);

  useEffect(() => {
    const eventKey = `${RAFEventName.ReloadPinnedTaskContent}_${RAFEntityName.Task
      }_${"PinnedNotesOnly"}_${careRecipientUID}`;
    subscribeRAFEvent(eventKey, refreshTaskList);

    return () => {
      unsubscribeRAFEvent(eventKey, refreshTaskList);
    };
  });

  const refreshTaskList = (args) => {
    if (isNotNullAndUndefined(args) && isNotNullAndUndefined(args.detail)) {
      let argscareRecipientUID = args.detail.careRecipientUID;
      if (argscareRecipientUID === careRecipientUID) {
        refresh();
      }
    }
  };

  const refresh = async () => {
    setState({
      isLoading: true,
      showManageClientNoteContent: false,
    });

    if (isNotNullAndUndefined(careRecipientUID)) {
      const sortQuery: string[] = [
        propertyOf<TaskRow>("Pinned"),
        propertyOf<TaskRow>("SortOrder"),
        `${propertyOf<TaskRow>("TaskDate")} desc`,
      ];

      const taskItemsRow = await getPlannerRelatedRecords(
        careRecipientUID,
        null,
        RAFTaskType.Note,
        sortQuery
      );

      setState({
        isLoading: false,
        taskItemsRow,
      });
    } else {
      setState({
        isLoading: false,
        taskItemsRow: [],
      });
    }
  };

  const refreshOnUpdate = async (isPinnedItem: boolean) => {
    if (isPinnedItem) {
      const eventKey = `${RAFEventName.ReloadPinnedTaskContent}_${RAFEntityName.Task}_PinnedNotesOnly_${careRecipientUID}`;
      triggerRAFEvent(eventKey, {
        careRecipientUID: `${careRecipientUID}`,
      });
    }
    refresh();
  };

  //manage ClientNote_start
  const showManageClientNoteContent = (selectedObjectUID) => {
    setState({ showManageClientNoteContent: true, selectedObjectUID });
  };

  const manageClientNoteContent = () => {
    if (state.showManageClientNoteContent) {
      return (
        <>
          <ManageTaskActivity
            objectUID={state.selectedObjectUID}
            relatedTo={careRecipientName}
            relatedToUID={careRecipientUID}
            relatedToType={props.relatedToType}
            onSave={refreshOnUpdate.bind(this)}
            onClose={closeManageClientNoteDialog.bind(this)}
            onDelete={refreshOnUpdate.bind(this)}

            defaultTaskTypeValue={RAFTaskType.Note}
            isActive
          />
        </>
      );
    } else {
      return <div></div>;
    }
  };

  const closeManageClientNoteDialog = async () => {
    setState({ showManageClientNoteContent: false, selectedObjectUID: null });
  };

  //manage ClientNote_end

  //show more ClientNote start
  const showMoreClientNoteContent = () => {
    if (isNotNullAndUndefined(props.viewAllClick)) {
      props.viewAllClick();
    }
    else {
      setState({ showMoreClientNoteContent: true });

    }
  };

  const moreClientNoteDialogContent = () => {
    if (state.showMoreClientNoteContent) {
      return (
        <div className="p-2 p-md-3 h-100 section__div customScrollBar">
          <ClientNoteList
            take={0}
            careRecipientUID={careRecipientUID}
            careRecipientName={careRecipientName}
            relatedToType={props.relatedToType}
            allowAdd={true}
            showEmptyState
          />
        </div>
      );
    } else {
      return null;
    }
  };

  const closeMoreClientNoteDialog = async () => {
    setState({ showMoreClientNoteContent: false });
  };

  //show more ClientNote end

  //create client note start
  const onAddNoteClicked = () => {
    setState({ showManageClientNoteContent: true, selectedObjectUID: null });
  };

  // useImperativeHandle(ref, () => ({
  //   onAddNoteClicked() {
  //     onAddNoteClicked();
  //   },
  // }));

  //create client note end

  const headerTemplate = (item: TaskRow) => {
    return (
      <div
        className={`position-relative pointer`}
      >
        <div className={`align-items-center row g-3 flex-nowrap`}>
          <div className="col">
            <div className="d-flex align-items-center justify-content-between">
              <span className="subtitle_1 ecllipseFirstLine">
                {item.Title}
              </span>
              {item.Pinned && (
                <span className="note_pin_outer_div">
                  <i className="note_pin_icon fas fa-thumbtack text_orange"></i>
                  {/* <i className="note_pin_icon fas fa-thumbtack" style={{ color: item.ColourCode }}></i> */}
                </span>
              )}
            </div>
            <div className="d-flex align-items-baseline body_3"
              onClick={(e) => e.stopPropagation()}>
              <RAFDetailsValueWithSeparator
                outerClassName="h-auto"
              >
                <div className="d-flex align-items-center">
                  <span className="body_3 pe-1">
                    {item.Pinned ? "Pinned" : "Added"}
                  </span>
                  <span>
                    {isNotNullAndUndefined(item.CreatedBy) ? (
                      <span className="d-flex align-items-center">
                        <span className="body_3 pe-1">by</span>
                        <span className="body_3 text-decoration-underline pe-1 ecllipseFirstLine word-break-all">
                          {item.CreatedBy}
                        </span>
                      </span>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
                <div>
                  <RAFRecordInfo
                    createdBy={item.CreatedBy}
                    createdDate={item.CreatedDate}
                    modifiedDate={item.ModifiedDate}
                    modifiedBy={item.ModifiedBy}
                    lastActivityDate={item.LastActivityDate}
                    content="updated:"
                    hideClockIcon
                    dropdownBtnClassName="unset-custom-button-md p-0 remove-minWidth"
                    spanContentClassName="body_3"
                  ></RAFRecordInfo>
                </div>
              </RAFDetailsValueWithSeparator>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getFooterContent = (showAddBtn: boolean) => {
    return (
      <div className="row gx-2">
        <RAFPermissionRender permissionName={PermissionConstants.TaskManage}>
          <div className={"col-auto"}>
            <RAFButtonComponent
              isPrimary
              action={RAFButtonConstant.Add}
              btnContent="Add Note"
              onClick={onAddNoteClicked}
              className="btn_brand_primary semi_dark"
            ></RAFButtonComponent>
          </div>
        </RAFPermissionRender>
        <div className="col-auto">
          <RAFButtonComponent
            isPrimary
            action={RAFButtonConstant.ShowMore}
            btnContent="View All"
            iconCss={"fas fa-arrow-right-long"}
            onClick={showMoreClientNoteContent}
            className="btn_brand_primary outline"
            showIcon={false}
          ></RAFButtonComponent>
        </div>
      </div>
    );
  };

  const getHeaderTitle = () => {
    const { taskItemsRow } = state;
    const pinnedNotesLength = isNotEmptyArray(taskItemsRow)
      ? taskItemsRow.filter((x) => x.Pinned).length
      : 0;
    const totalNotesLength = isNotEmptyArray(taskItemsRow)
      ? taskItemsRow.length
      : 0;

    return (
      <div>
        <div className="row gx-2">
          <div className="col">
            <div className="row gx-2 gy-1">
              <div className="col-12">
                <span className="header_5">{`Notes`}</span>
              </div>
              <div className="col-auto">
                <div className="body_2">
                  <span>Pinned notes:</span>
                  <span className="content_neutral_dark medium">
                    {pinnedNotesLength}
                  </span>
                </div>
              </div>
              <div className="col-auto">
                <div className="group_btn_separator"></div>
              </div>
              <div className="col-auto">
                <div className="body_2">
                  <span>Total notes :</span>
                  <span className="content_neutral_dark medium">
                    {totalNotesLength}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-auto">
            {getFooterContent(
              hasPermission(
                permissionValue,
                PermissionConstants.TaskManage
              )
                ? true
                : false
            )}
          </div>
        </div>
        {/* <span className="ms-2 raf-badge-circle raf-badge-tertiary-circle">
            {isNotEmptyArray(state.taskItemsRow) ? state.taskItemsRow.length : 0}
          </span> */}
      </div>
    );
  };

  const { taskItemsRow } = state;
  const pinnedNotes = isNotEmptyArray(taskItemsRow)
    ? taskItemsRow.filter((x) => x.Pinned)
    : [];

  const getNotesContentDiv = () => {
    return (
      <>
        {isNotEmptyArray(pinnedNotes) ? (
          pinnedNotes.map((item) => {
            const colorCodeName = isNotNullAndUndefined(item.ColourCode)
              ? item.ColourCode
              : "#67C6C2";
            return (
              <div
                key={item.UID}
                className={
                  props.viewMode === "Horizontal" ? "col-auto" : "col-12"
                }
                {...(BrowserIsDevice && props.viewMode === "Horizontal"
                  ? { style: { width: "80%", minWidth: "300px" } }
                  : {})}
                onClick={() => showManageClientNoteContent(item.UID)}
              >
                <CustomCardWidget
                  cardContentClassName={paddingClassname}
                  style={{
                    backgroundColor: colorCodeName,
                    borderColor: colorCodeName,
                  }}
                >
                  {headerTemplate(item)}
                  {IsNotNullOrWhiteSpace(item.Description) && (
                    <div
                      id={"summaryTask_" + item.UID}
                      // style={{ backgroundColor: item.ColorCode }}
                      className="mt-2"
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html:
                            item !== null
                              ? DOMPurify.sanitize(item.Description)
                              : undefined,
                        }}
                        className="remove_paragraph_margin body_2_dark description-text ecllipseSecondLine ecllipse_4"
                      ></span>
                    </div>
                  )}
                </CustomCardWidget>
              </div>
            );
          })
        ) : (
          <div className={"col-12"}>
            <RAFEmptyState title={"You do not have any pinned notes."} />
          </div>
        )}
      </>
    );
  };

  return (
    <Fragment>
      <div className="row g-0 gy-3">
        <div className="col-12">
          {getHeaderTitle()}
        </div>
        <div className="col-12">
          {state.isLoading === false ? (
            <RAFPermissionRender
              permissionName={PermissionConstants.TaskRead}
            >
              <div className="section__container">
                <div
                  className={`${isNotEmptyArray(pinnedNotes) &&
                    props.viewMode === "Horizontal"
                    ? `${!BrowserIsDevice ? " customScrollBar" : ""
                    }overflow-auto`
                    : ""
                    }`}
                >
                  <div
                    className={`row gy-3 g-0${isNotEmptyArray(pinnedNotes) &&
                      props.viewMode === "Horizontal"
                      ? " gx-3 flex-nowrap"
                      : " flex-column"
                      }`}
                  >
                    {getNotesContentDiv()}
                    {state.showManageClientNoteContent && (
                      <DialogComponent
                        //  header={isNotNullAndUndefined(state.selectedObjectUID) ? "Update Note" : 'Add Note'}
                        //showCloseIcon={false}
                        visible={state.showManageClientNoteContent}
                        cssClass={
                          "rightDialog createEditForm full-height dlg-new-style"
                        }
                        id="manageupdate_clientnote_dialog"
                        content={manageClientNoteContent.bind(this)}
                        isModal
                        target="body"
                        closeOnEscape={false}
                        close={closeManageClientNoteDialog.bind(this)}
                        open={PreventFocusOnDialogOpen}
                        zIndex={1200}
                      />
                    )}
                  </div>
                </div>
              </div>
            </RAFPermissionRender>
          ) : (
            <div className="container-fluid px-0">
              <ACLoadingPanel loadingText="Loading..." />
            </div>
          )}
        </div>
      </div>
      <div>
        {state.showMoreClientNoteContent && (
          <DialogComponent
            header={"Notes"}
            showCloseIcon
            visible={state.showMoreClientNoteContent}
            cssClass="rightDialog rightDialog-lg createEditForm full-height form-center-dialog dlg-new-style"
            //cssClass={"rightDialog createEditForm full-height dlg-new-style"}
            id="more_clientnote_dialog"
            content={moreClientNoteDialogContent.bind(this)}
            isModal
            target="body"
            closeOnEscape={false}
            close={closeMoreClientNoteDialog.bind(this)}
            open={PreventFocusOnDialogOpen}
            zIndex={1200}
          />
        )}
      </div>
    </Fragment>
  );
}

export default React.memo(ClientProfileNoteList);
