import moment from "moment";
import * as R from "ramda";
import { msalInstance } from "../../../..";
import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import {
  isNotEmptyArray,
  isNotNullAndUndefined,
  propertyOf,
  setZeroHours,
} from "../../../../RAFComponents/helpers/utils";
import { ListServiceRequest } from "../../../../RAFComponents/models/Common/ListRequest";
import * as repositoryActions from "../../../../RAFComponents/store/actions/repositoryActions";
import {
  ContentType,
  RAFActionStatus,
  RAFTaskType,
} from "../../../../constants/Common/Constants";
import { ScheduleFilterRow } from "../../../ActiveContacts/ServiceTransaction/ServiceTransactionHelper";
import {
  TaskActivityFilterUIType,
  TaskRow,
} from "../../../ActiveContacts/Task/TaskRow";

export class TaskActivitiesFilterRow {
  TaskType: string;
  TaskStatus: string;
  UIType: string;
  SortByAction: "Sort" | "By Date" | "By Recent" | 'By Old';
}

const getASAPCustomFilter = () => {
  let asapCustomFilter: RAFCustomFilter = {};
  asapCustomFilter.Condition = "and";
  asapCustomFilter.Rules = [];

  let asapDateFilter: RAFCustomFilter = {};
  let asapDateFilterVal: string[] = [];
  asapDateFilter.Operator = RAFCustomOperator.LesserThan;
  asapDateFilterVal.push("1901-01-01 23:59:59.999Z" as any);
  asapDateFilter.Value = asapDateFilterVal;
  asapDateFilter.Field = "TaskDate";

  let asapDateFilter1: RAFCustomFilter = {};
  let asapDateFilterVal1: string[] = [];
  asapDateFilter1.Operator = RAFCustomOperator.GreaterThanOrEqual;
  asapDateFilterVal1.push("1901-01-01 00:00:00.000Z" as any);
  asapDateFilter1.Value = asapDateFilterVal1;
  asapDateFilter1.Field = "TaskDate";

  asapCustomFilter.Rules.push(asapDateFilter);
  asapCustomFilter.Rules.push(asapDateFilter1);

  return asapCustomFilter;
};

const getUnplannedCustomFilter = () => {
  let unplannedCustomFilter: RAFCustomFilter = {};
  unplannedCustomFilter.Condition = "and";
  unplannedCustomFilter.Rules = [];

  let unplannedFilter: RAFCustomFilter = {};
  let unplannedFilterVal: string[] = [];
  unplannedFilter.Operator = RAFCustomOperator.LesserThanOrEqual;
  unplannedFilterVal.push("9999-12-31 00:00:00.000" as any);
  unplannedFilter.Value = unplannedFilterVal;
  unplannedFilter.Field = "TaskDate";

  let unplannedFilter1: RAFCustomFilter = {};
  let unplannedFilterVal1: string[] = [];
  unplannedFilter1.Operator = RAFCustomOperator.GreaterThanOrEqual;
  unplannedFilterVal1.push("9999-12-31 00:00:00.000" as any);
  unplannedFilter1.Value = unplannedFilterVal1;
  unplannedFilter1.Field = "TaskDate";

  unplannedCustomFilter.Rules.push(unplannedFilter);
  unplannedCustomFilter.Rules.push(unplannedFilter1);

  return unplannedCustomFilter;
};

export const getPlannerAdditionalListFilter = (
  relatedToUID: string,
  taskActivitiesFilterRow: TaskActivitiesFilterRow,
  defaultTaskTypes: {
    id: string;
    text: string;
  }[],
  defaultTaskType: string,
  secondaryRelatedToUID: string,
  parentRelatedToUID: string
) => {
  let taskActivitiesFilter1: RAFCustomFilter = {};
  taskActivitiesFilter1.Condition = "and";
  taskActivitiesFilter1.Rules = [];

  const cloneTaskActivitiesFilterRow: TaskActivitiesFilterRow =
    isNotNullAndUndefined(taskActivitiesFilterRow)
      ? R.clone(taskActivitiesFilterRow)
      : null;

  const taskFilterType = isNotNullAndUndefined(defaultTaskType)
    ? defaultTaskType
    : isNotNullAndUndefined(cloneTaskActivitiesFilterRow)
      ? cloneTaskActivitiesFilterRow.TaskType
      : null;

  const filterByTaskTypes = (isNotNullAndUndefined(taskFilterType) && taskFilterType !== "AllType" && taskFilterType !== "All") ? [taskFilterType] :
    isNotEmptyArray(defaultTaskTypes) ? defaultTaskTypes.map(x => x.id) : null;

  const taskStatus = isNotNullAndUndefined(cloneTaskActivitiesFilterRow)
    ? cloneTaskActivitiesFilterRow.TaskStatus
    : null;

  const taskUIType = isNotNullAndUndefined(cloneTaskActivitiesFilterRow)
    ? cloneTaskActivitiesFilterRow.UIType
    : null;

  if (taskUIType === TaskActivityFilterUIType.CreatedByMe) {
    let createdByMeFilter: RAFCustomFilter = {};
    let createdByMeFilterVal: string[] = [];
    createdByMeFilterVal.push(msalInstance.currentUserId);
    createdByMeFilter.Operator = RAFCustomOperator.Equal;
    createdByMeFilter.Value = createdByMeFilterVal;
    createdByMeFilter.Field = propertyOf<TaskRow>("CreatedByUID");
    taskActivitiesFilter1.Rules.push(createdByMeFilter);
  } else if (taskUIType === TaskActivityFilterUIType.AssignedToMe) {
    let assignedToMeFilter: RAFCustomFilter = {};
    let assignedToMeFilterVal: string[] = [];
    assignedToMeFilterVal.push(msalInstance.currentUserId);
    assignedToMeFilter.Operator = RAFCustomOperator.Equal;
    assignedToMeFilter.Value = assignedToMeFilterVal;
    assignedToMeFilter.Field = propertyOf<TaskRow>("AssigneeUID");
    taskActivitiesFilter1.Rules.push(assignedToMeFilter);
  } else if (taskUIType === TaskActivityFilterUIType.CompletedByMe) {
    let completedByMeFilter: RAFCustomFilter = {};
    let completedByMeFilterVal: string[] = [];
    completedByMeFilterVal.push(msalInstance.currentUserId);
    completedByMeFilter.Operator = RAFCustomOperator.Equal;
    completedByMeFilter.Value = completedByMeFilterVal;
    completedByMeFilter.Field = propertyOf<TaskRow>("CompletedByUID");
    taskActivitiesFilter1.Rules.push(completedByMeFilter);
  }

  if (taskStatus === 'Open & Overdue') {
    let taskStatusFilter: RAFCustomFilter = {};
    let taskStatusFilterVal: string[] = [];
    taskStatusFilterVal.push(RAFActionStatus.Completed);
    taskStatusFilter.Operator = RAFCustomOperator.NotEqual;
    taskStatusFilter.Value = taskStatusFilterVal;
    taskStatusFilter.Field = propertyOf<TaskRow>("TaskStatus");
    taskActivitiesFilter1.Rules.push(taskStatusFilter);

  } else if (taskStatus === 'Overdue') {
    let taskStatusFilter: RAFCustomFilter = {};
    let taskStatusFilterVal: string[] = [];
    taskStatusFilterVal.push(RAFActionStatus.Completed);
    taskStatusFilter.Operator = RAFCustomOperator.NotEqual;
    taskStatusFilter.Value = taskStatusFilterVal;
    taskStatusFilter.Field = propertyOf<TaskRow>("TaskStatus");
    taskActivitiesFilter1.Rules.push(taskStatusFilter);

    const today = setZeroHours(new Date());

    let OverdueDateFilter: RAFCustomFilter = {};
    let OverdueDateFilterVal: string[] = [];
    OverdueDateFilterVal.push(moment(today).toDate() as any);
    OverdueDateFilter.Operator = RAFCustomOperator.LesserThan;
    OverdueDateFilter.Value = OverdueDateFilterVal;
    OverdueDateFilter.Field = propertyOf<TaskRow>("TaskDate");
    taskActivitiesFilter1.Rules.push(OverdueDateFilter);

  } else if (isNotNullAndUndefined(taskStatus) && taskStatus !== "AllStatus") {
    let taskStatusFilter: RAFCustomFilter = {};
    let taskStatusFilterVal: string[] = [];
    taskStatusFilterVal.push(taskStatus);
    taskStatusFilter.Operator = RAFCustomOperator.Equal;
    taskStatusFilter.Value = taskStatusFilterVal;
    taskStatusFilter.Field = propertyOf<TaskRow>("TaskStatus");
    taskActivitiesFilter1.Rules.push(taskStatusFilter);
  }

  if (isNotNullAndUndefined(relatedToUID)) {
    let customTaskRelatedToFilter: RAFCustomFilter = {};
    customTaskRelatedToFilter.Condition = "or";
    customTaskRelatedToFilter.Rules = [];

    let relatedToFilter: RAFCustomFilter = {};
    let relatedToFilterVal: string[] = [];

    relatedToFilterVal.push(relatedToUID);
    relatedToFilter.Operator = RAFCustomOperator.Equal;
    relatedToFilter.Value = relatedToFilterVal;
    relatedToFilter.Field = propertyOf<TaskRow>("RelatedToUID");
    customTaskRelatedToFilter.Rules.push(relatedToFilter);

    let secondaryToFilter: RAFCustomFilter = {};
    let secondaryToFilterVal: string[] = [];

    secondaryToFilterVal.push(relatedToUID);
    secondaryToFilter.Operator = RAFCustomOperator.Equal;
    secondaryToFilter.Value = secondaryToFilterVal;
    secondaryToFilter.Field = propertyOf<TaskRow>("SecondaryRelatedToUID");
    customTaskRelatedToFilter.Rules.push(secondaryToFilter);

    taskActivitiesFilter1.Rules.push(customTaskRelatedToFilter);
  }

  if (isNotNullAndUndefined(secondaryRelatedToUID)) {
    let secondaryRelatedToFilter: RAFCustomFilter = {};
    let secondaryRelatedToFilterVal: string[] = [];

    secondaryRelatedToFilterVal.push(secondaryRelatedToUID);
    secondaryRelatedToFilter.Operator = RAFCustomOperator.Equal;
    secondaryRelatedToFilter.Value = secondaryRelatedToFilterVal;
    secondaryRelatedToFilter.Field = propertyOf<TaskRow>(
      "SecondaryRelatedToUID"
    );
    taskActivitiesFilter1.Rules.push(secondaryRelatedToFilter);
  }

  if (isNotNullAndUndefined(parentRelatedToUID)) {
    let parentRelatedToFilter: RAFCustomFilter = {};
    let parentRelatedToFilterVal: string[] = [];

    parentRelatedToFilterVal.push(parentRelatedToUID);
    parentRelatedToFilter.Operator = RAFCustomOperator.Equal;
    parentRelatedToFilter.Value = parentRelatedToFilterVal;
    parentRelatedToFilter.Field = propertyOf<TaskRow>("ParentUID");
    taskActivitiesFilter1.Rules.push(parentRelatedToFilter);
  } else {
    let nullParentUIDFilter: RAFCustomFilter = {};
    nullParentUIDFilter.Operator = RAFCustomOperator.IsNull;
    nullParentUIDFilter.Field = propertyOf<TaskRow>("ParentUID");
    taskActivitiesFilter1.Rules.push(nullParentUIDFilter);
  }

  if (
    isNotEmptyArray(filterByTaskTypes)
  ) {
    let customTaskTypeFilter: RAFCustomFilter = {};
    customTaskTypeFilter.Condition = "or";
    customTaskTypeFilter.Rules = [];

    filterByTaskTypes.forEach((taskType) => {
      let taskTypeFilter: RAFCustomFilter = {};
      let taskTypeFilterVal: string[] = [];
      taskTypeFilterVal.push(taskType);
      taskTypeFilter.Operator = RAFCustomOperator.Equal;
      taskTypeFilter.Value = taskTypeFilterVal;
      taskTypeFilter.Field = propertyOf<TaskRow>("TaskType");
      customTaskTypeFilter.Rules.push(taskTypeFilter);
    });
    taskActivitiesFilter1.Rules.push(customTaskTypeFilter);
  }

  return taskActivitiesFilter1;
};

export const getPlannerRelatedRecords = (
  relatedToUID: string,
  taskActivitiesFilterRow: TaskActivitiesFilterRow,
  defaultTaskType?: RAFTaskType,
  sortQuery?: string[],
  secondaryRelatedToUID?: string,
  parentRelatedToUID?: string,
  skipItemsOnScroll?: number,
  takeItemsOnScroll?: number
) => {
  return new Promise<TaskRow[]>((resolve) => {
    const listRequest: ListServiceRequest = getPlannerRelatedListServiceRequest(
      relatedToUID,
      taskActivitiesFilterRow,
      null,
      defaultTaskType,
      sortQuery,
      secondaryRelatedToUID,
      parentRelatedToUID,
      skipItemsOnScroll,
      takeItemsOnScroll
    );

    const uiType = isNotNullAndUndefined(taskActivitiesFilterRow)
      ? taskActivitiesFilterRow.UIType
      : null;
    const url =
      uiType === TaskActivityFilterUIType.AssignedToMyTeam
        ? `Task/MyTeamTasks`
        : `Task/List`;

    return repositoryActions
      .postDataAndGetResponse(
        url,
        listRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (isNotNullAndUndefined(response)) {
          if (response.status === 204) {
            resolve(null);
          } else if (isNotNullAndUndefined(response.data) && isNotNullAndUndefined(response.data.Entities)) {
            resolve(response.data.Entities);
          } else {
            resolve(null);
          }
        } else {
          resolve(null);
        }
      });
  });
};

const getPlannerRelatedListServiceRequest = (
  relatedToUID: string,
  taskActivitiesFilterRow: TaskActivitiesFilterRow,
  defaultTaskTypes: {
    id: string;
    text: string;
  }[],
  defaultTaskType: RAFTaskType,
  sortQuery: string[],
  secondaryRelatedToUID: string,
  parentRelatedToUID: string,
  skipItemsOnScroll: number,
  takeItemsOnScroll: number
) => {
  const customFilter = getPlannerAdditionalListFilter(
    relatedToUID,
    taskActivitiesFilterRow,
    defaultTaskTypes,
    defaultTaskType,
    secondaryRelatedToUID,
    parentRelatedToUID
  );


  const sortByAction = isNotNullAndUndefined(taskActivitiesFilterRow)
    ? taskActivitiesFilterRow.SortByAction
    : null;

  const listSortQuery: string[] = isNotEmptyArray(sortQuery) ? sortQuery : [];

  if (sortByAction === "By Date") {
    listSortQuery.push(`${propertyOf<TaskRow>("TaskDate")} desc`);
  } else if (sortByAction === "By Recent") {
    listSortQuery.push(`${propertyOf<TaskRow>("ModifiedDate")} desc`);
  } else if (sortByAction === "By Old") {
    listSortQuery.push(`${propertyOf<TaskRow>("ModifiedDate")} asc`);
  }

  const listRequest: ListServiceRequest = {
    Skip: isNotNullAndUndefined(skipItemsOnScroll) ? skipItemsOnScroll : 0,
    Take: isNotNullAndUndefined(takeItemsOnScroll) ? takeItemsOnScroll : 0,
    CustomFilter: customFilter,
    Sort: listSortQuery,
  };

  return listRequest;

};

export const getPlannerRelatedRecordsAndTotalCount = (
  relatedToUID: string,
  taskActivitiesFilterRow: TaskActivitiesFilterRow,
  defaultTaskTypes: {
    id: string;
    text: string;
  }[],
  defaultTaskType: RAFTaskType,
  sortQuery: string[],
  secondaryRelatedToUID: string,
  parentRelatedToUID: string,
  skipItemsOnScroll: number,
  takeItemsOnScroll: number
) => {
  return new Promise<{ taskRows: TaskRow[], totalCount: number; }>((resolve) => {

    const listRequest: ListServiceRequest = getPlannerRelatedListServiceRequest(
      relatedToUID,
      taskActivitiesFilterRow,
      defaultTaskTypes,
      defaultTaskType,
      sortQuery,
      secondaryRelatedToUID,
      parentRelatedToUID,
      skipItemsOnScroll,
      takeItemsOnScroll
    );

    const uiType = isNotNullAndUndefined(taskActivitiesFilterRow)
      ? taskActivitiesFilterRow.UIType
      : null;
    const url =
      uiType === TaskActivityFilterUIType.AssignedToMyTeam
        ? `Task/MyTeamTasks`
        : `Task/List`;

    return repositoryActions
      .postDataAndGetResponse(
        url,
        listRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (isNotNullAndUndefined(response)) {
          if (response.status === 204) {
            resolve(null);
          } else if (isNotNullAndUndefined(response.data) && isNotNullAndUndefined(response.data.Entities)) {
            const returnValue = { taskRows: response.data.Entities, totalCount: response.data.TotalCount };
            resolve(returnValue);
          } else {
            resolve(null);
          }
        } else {
          resolve(null);
        }
      });
  });
};

export const convertPlannerRowRAFCustomFilter = (
  scheduleFilterRow: ScheduleFilterRow
): RAFCustomFilter[] => {
  if (isNotNullAndUndefined(scheduleFilterRow)) {
    let relatedToFilters: RAFCustomFilter[] = [];
    const filterValues: ScheduleFilterRow = { ...scheduleFilterRow };
    if (isNotNullAndUndefined(filterValues)) {
      let eValue1 = filterValues.StartDate
        ? setZeroHours(filterValues.StartDate)
        : null;
      let eValue2 = filterValues.EndDate
        ? setZeroHours(filterValues.EndDate)
        : null;

      let eValueStatus = filterValues.Status;

      const selectedEntity = filterValues.Entity;

      delete filterValues.RelatedToType;
      delete filterValues.DateRange;
      delete filterValues.Assignee;
      delete filterValues.RelatedTo;
      delete filterValues.StartDate;
      delete filterValues.EndDate;
      delete filterValues.SelectedDate;
      delete filterValues.Entity;

      if (isNotNullAndUndefined(eValueStatus) && eValueStatus === "AllStatus") {
        delete filterValues.Status;
      }

      if (isNotNullAndUndefined(eValue1) && isNotNullAndUndefined(eValue2)) {
        let evalue1Filter: RAFCustomFilter = {};
        let evalue1FilterVal: string[] = [];
        evalue1FilterVal.push(eValue1 as any); //28/12/2023 post  -- 27/12/2023/18:30:00 somthing greater than equal
        evalue1Filter.Operator = RAFCustomOperator.GreaterThanOrEqual;
        evalue1Filter.Value = evalue1FilterVal;
        evalue1Filter.Field = "TaskDate";
        relatedToFilters.push(evalue1Filter);

        let evalue2Filter: RAFCustomFilter = {};
        let evalue2FilterVal: string[] = [];
        //if (moment(eValue1).isSame(eValue2)) {  //30/12/2023 post  -- 30/12/2023/18:30:00 somthing less than
        let nextDay = moment(eValue2).add(1, "days").toDate();
        evalue2FilterVal.push(nextDay as any);
        evalue2Filter.Operator = RAFCustomOperator.LesserThan;
        // } else {
        //   evalue2FilterVal.push(eValue2 as any); //30/12/2023 post  -- 29/12/2023/18:30:00 somthing less than or equal
        //   evalue2Filter.Operator = RAFCustomOperator.LesserThanOrEqual;
        // }

        evalue2Filter.Value = evalue2FilterVal;
        evalue2Filter.Field = "TaskDate";
        relatedToFilters.push(evalue2Filter);
      }

      if (isNotNullAndUndefined(eValueStatus) && eValueStatus !== "AllStatus") {
        let evalueStatusFilter: RAFCustomFilter = {};
        let evalueStatusFilterVal: string[] = [];
        evalueStatusFilterVal.push(eValueStatus);
        evalueStatusFilter.Operator = RAFCustomOperator.Equal;
        evalueStatusFilter.Value = evalueStatusFilterVal;
        evalueStatusFilter.Field = "TaskStatus";
        relatedToFilters.push(evalueStatusFilter);
      }

      // let sourceObject = filterValues;
      // for (let key in sourceObject) {
      //   let relatedFilter: RAFCustomFilter = {};
      //   let relatedFilterVal: string[] = [];
      //   relatedFilterVal.push(sourceObject[key]);
      //   relatedFilter.Operator = RAFCustomOperator.Equal;
      //   relatedFilter.Value = relatedFilterVal;
      //   relatedFilter.Field = key;

      //   if (isNotNullAndUndefined(sourceObject[key]))
      //     relatedToFilters.push(relatedFilter);
      // }
    }

    return relatedToFilters;
  } else {
    return [];
  }
};
