import { SwitchComponent } from "@syncfusion/ej2-react-buttons";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import DOMPurify from "dompurify";
import React, {
  PropsWithChildren,
  Reducer,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { RolePermissionsContext } from "../../../../RAFAuthentication/RAFRolePermissionsContextProvider";
import { PreventFocusOnDialogOpen } from "../../../../RAFComponents/Dialog/SFDialogUtils";
import { getFormatedDate } from "../../../../RAFComponents/Inputs/RFFUtils";
import CustomCardWidget from "../../../../RAFComponents/Navigation/CustomCardWidget";
import RAFButtonComponent from "../../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFEmptyState from "../../../../RAFComponents/Navigation/RAFEmptyState";
import { RAFCustomFilter } from "../../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import RAFPermissionRender, {
  hasPermission,
} from "../../../../RAFComponents/helpers/PermissionHelper";
import {
  RAFEventName,
  subscribeRAFEvent,
  triggerRAFEvent,
  unsubscribeRAFEvent,
} from "../../../../RAFComponents/helpers/RAFDocumentEvents";
import {
  IsNotNullOrWhiteSpace,
  isNotEmptyArray,
  isNotNullAndUndefined,
  propertyOf,
} from "../../../../RAFComponents/helpers/utils";
import ACLoadingPanel from "../../../../components/shared/ACLoadingPanel";
import { CareRecipientPermissionConstants } from "../../../../constants/CareESIO/CareESIOPermissionConstant";
import { CareEsioEntity } from "../../../../constants/CareESIO/CareEsioConstants";
import {
  MomentFormats,
  RAFButtonConstant,
  RAFTaskType
} from "../../../../constants/Common/Constants";
import { RAFEntityName } from "../../../../constants/Common/EntityConstants";
import ManageTaskActivity from "../../../ActiveContacts/Task/TaskActivity/ManageTaskActivity";
import { TaskRow } from "../../../ActiveContacts/Task/TaskRow";
import UserProfilePhoto from "../../../ActiveContacts/User/UserProfilePhoto";
import { getPlannerRelatedRecords } from "../PlannerTask/PlannerLogHelper";
import "./ClientNoteStyle.scss";

interface IProps {
  take?: number;
  relatedToType?: string;
  careRecipientUID?: string;
  careRecipientName?: string;

  secondaryRelatedTo?: string;
  secondaryRelatedToUID?: string;
  secondaryRelatedToType?: string;

  shiftUID?: string;

  mode?: "PinnedNotesOnly" | "PinnedNotesOrShiftNotes" | "UnPinnedNotesOnly";
  viewMode?: "Horizontal" | "Vertical";
  allowAdd?: boolean;
  showEmptyState?: boolean;
  uiMode?: "NormalCard" | "CardWithLeftBorder";
  headerMode?: "Avatar" | "Icon";

  isRelatedSection?: boolean;
  modifiedDateRangeFilter?: RAFCustomFilter[];
  displayStyle?: "ImageOnly" | "TextOnly" | "Default";
  paddingClassname?: string;

  showHeaderTitle?: boolean;
  btnPosition?: "Top" | "Bottom";
}

interface IState {
  isLoading: boolean;
  taskItemsRow: TaskRow[];
  showManageClientNoteContent: boolean;
  selectedObjectUID: string;
}

function ClientNoteList({
  careRecipientUID,
  shiftUID,
  careRecipientName,
  uiMode,
  headerMode = "Avatar",
  allowAdd = true,
  showEmptyState = false,
  isRelatedSection = false,
  paddingClassname = "p-2 p-md-3",
  btnPosition = "Bottom",
  ...props
}: PropsWithChildren<IProps>) {
  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      taskItemsRow: null,
      showManageClientNoteContent: false,
      selectedObjectUID: null,
    }
  );

  const rolePermissionsContext = useContext(RolePermissionsContext);
  const permissionValue = isNotNullAndUndefined(rolePermissionsContext)
    ? rolePermissionsContext.permissionValue
    : null;

  useEffect(() => {
    refresh();
  }, [careRecipientUID, props.modifiedDateRangeFilter]);

  useEffect(() => {
    if (props.mode === "PinnedNotesOnly") {
      const eventKey = `${RAFEventName.ReloadPinnedTaskContent}_${RAFEntityName.Task}_${props.mode}_${careRecipientUID}`;
      subscribeRAFEvent(eventKey, refreshTaskList);

      return () => {
        unsubscribeRAFEvent(eventKey, refreshTaskList);
      };
    }
  });

  const refreshTaskList = (args) => {
    if (isNotNullAndUndefined(args) && isNotNullAndUndefined(args.detail)) {
      let argscareRecipientUID = args.detail.careRecipientUID;
      if (argscareRecipientUID === careRecipientUID) {
        refresh();
      }
    }
  };

  const refresh = async () => {
    setState({
      isLoading: true,
      showManageClientNoteContent: false,
    });
    if (isNotNullAndUndefined(careRecipientUID)) {
      const sortQuery: string[] = [
        propertyOf<TaskRow>("Pinned"),
        propertyOf<TaskRow>("SortOrder"),
        `${propertyOf<TaskRow>("TaskDate")} desc`,
      ];

      const taskItemsRow = await getPlannerRelatedRecords(
        careRecipientUID,
        null,
        RAFTaskType.Note,
        sortQuery
      );

      setState({
        isLoading: false,
        taskItemsRow,
      });
    } else {
      setState({
        isLoading: false,
        taskItemsRow: [],
      });
    }
  };

  const refreshOnUpdate = async (isPinnedItem: boolean) => {
    //if (props.mode !== "PinnedNotesOnly" && isPinnedItem) {
    if (props.mode !== "PinnedNotesOnly") {
      const eventKey = `${RAFEventName.ReloadPinnedTaskContent}_${RAFEntityName.Task}_PinnedNotesOnly_${careRecipientUID}`;
      triggerRAFEvent(eventKey, {
        careRecipientUID: `${careRecipientUID}`,
      });
    }
    refresh();
  };

  //manage ClientNote_start
  const showManageClientNoteContent = (selectedObjectUID: string) => {
    if (isNotNullAndUndefined(selectedObjectUID)) {
      setState({ showManageClientNoteContent: true, selectedObjectUID });
    } else {
      setState({ showManageClientNoteContent: true });
    }
  };

  const manageClientNoteContent = () => {
    if (state.showManageClientNoteContent) {
      return (
        <ManageTaskActivity
          objectUID={state.selectedObjectUID}
          relatedTo={careRecipientName}
          relatedToUID={careRecipientUID}
          relatedToType={props.relatedToType}
          secondaryRelatedTo={props.secondaryRelatedTo}
          secondaryRelatedToUID={props.secondaryRelatedToUID}
          secondaryRelatedToType={props.secondaryRelatedToType}
          onSave={refreshOnUpdate.bind(this)}
          onClose={closeManageClientNoteDialog.bind(this)}
          onDelete={refreshOnUpdate.bind(this)}
          defaultTaskTypeValue={RAFTaskType.Note}
          isActive
        />
      );
    } else {
      return <div></div>;
    }
  };

  const closeManageClientNoteDialog = async () => {
    setState({ showManageClientNoteContent: false, selectedObjectUID: null });
  };
  //manage ClientNote_end

  //create client note start
  const onAddNoteClicked = () => {
    setState({ showManageClientNoteContent: true, selectedObjectUID: null });
  };
  //create client note end

  const headerTemplate = (item: TaskRow) => {
    return (
      <div>
        <div>
          <span className="subtitle_1 semi_bold ecllipseFirstLine">
            {item.Title}
          </span>
        </div>
        <div className="mt-1 d-flex">
          <div className="row gx-1">
            <div className="col-auto">
              <div className="body_3_light">Modified Date:</div>
            </div>
            <div className="col-auto">
              <div className="body_3 semi_bold">
                {getFormatedDate(item.ModifiedDate, MomentFormats.DATETIME)}
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center mt-2">
          <UserProfilePhoto
            uid={
              isNotNullAndUndefined(item) &&
                IsNotNullOrWhiteSpace(item.CreatedByUID)
                ? item.CreatedByUID
                : ""
            }
            relatedToType={RAFEntityName.User}
            canEdit={false}
            profileImgCss={"profileImg-container-xsm"}
            title={item.CreatedBy}
            emptyAvatarType="Initial"
            iconSize="24"
            fontSize="10"
          />
          <span className="subtitle_3 ps-2 ecllipseFirstLine word-break-all">
            {item.CreatedBy}
          </span>
        </div>
      </div>
    );
  };

  const getFooterContent = (showAddBtn: boolean) => {
    // if (showAddBtn && BrowserIsDevice && isRelatedSection === false) {
    //   return (
    //     <RAFPermissionRender
    //       permissionName={ClientNotePermissionConstants.ClientNoteAdd}
    //     >
    //       <div>
    //         <FabComponent
    //           id={`btn_${RAFButtonConstant.Add.Id}_${CareEsioEntity.ClientNote.EntityName}`}
    //           iconCss={RAFButtonConstant.Add.IconCss}
    //           content={RAFButtonConstant.Add.DisplayName}
    //           onClick={onAddNoteClicked}
    //         // target={`#care_${CareEsioEntity.CareShiftLog.EntityName}_div`}
    //         ></FabComponent>
    //       </div>
    //     </RAFPermissionRender>
    //   );
    // } else
    if (showAddBtn) {
      return (
        <div className={"col-auto"}>
          <RAFButtonComponent
            action={RAFButtonConstant.Add}
            btnContent="Add Note"
            onClick={onAddNoteClicked}
            className="btn_brand_primary semi_dark"
          ></RAFButtonComponent>
        </div>
      );
    }
  };

  const getHeaderTitle = () => {
    const { taskItemsRow } = state;
    const pinnedNotesLength = isNotEmptyArray(taskItemsRow)
      ? taskItemsRow.filter((x) => x.Pinned).length
      : 0;
    const totalNotesLength = isNotEmptyArray(taskItemsRow)
      ? taskItemsRow.length
      : 0;

    return (
      <div>
        <div className="row gx-2">
          <div className="col">
            <div className="row gx-2 gy-1">
              <div className="col-12">
                <span className="header_5">{`Notes`}</span>
              </div>
              <div className="col-auto">
                <div className="body_2">
                  <span>Pinned notes:</span>
                  <span className="content_neutral_dark medium">
                    {pinnedNotesLength}
                  </span>
                </div>
              </div>
              <div className="col-auto">
                <div className="group_btn_separator"></div>
              </div>
              <div className="col-auto">
                <div className="body_2">
                  <span>Total notes:</span>
                  <span className="content_neutral_dark medium">
                    {totalNotesLength}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {getFooterContent(
            hasPermission(
              permissionValue,
              CareRecipientPermissionConstants.CareRecipientManageMyNotes
            )
              ? true
              : false
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="row g-0 gy-3">
      <div className="col-12">{getHeaderTitle()}</div>
      <div className="col-12">
        {state.isLoading === false ? (
          <RAFPermissionRender
            permissionName={
              CareRecipientPermissionConstants.CareRecipientReadNotes
            }
          >
            {isNotEmptyArray(state.taskItemsRow) &&
              state.taskItemsRow.length > 0 ? (
              <div className="row g-3">
                {state.taskItemsRow.map((item) => {
                  const colorCodeName = isNotNullAndUndefined(item.ColourCode)
                    ? item.ColourCode
                    : "#67C6C2";
                  return (
                    <div className="col-12">
                      {/* <div className="col-12 col-md-6 col-lg-12 col-xl-6 col-xxl-4"> */}
                      <CustomCardWidget
                        cardClassName="note_card"
                        onClick={() => showManageClientNoteContent(item.UID)}
                        style={{
                          backgroundColor: colorCodeName,
                          borderColor: colorCodeName,
                        }}
                      >
                        <div className="row gx-2">
                          <div className="col">{headerTemplate(item)}</div>
                          <div className="col-auto">
                            <div className="row g-0 justify-content-end align-items-center">
                              <div className="col-auto d-flex align-items-center">
                                <div className="body_3_light pe-1">Feed</div>
                                <SwitchComponent
                                  name={"feeds"}
                                  checked={item.PostFeed ?? false}
                                  disabled
                                />
                              </div>
                              <div className="col-auto">
                                <div className="group_btn_separator"></div>
                              </div>
                              <div className="col-auto">
                                <span className="note_pin_outer_div">
                                  {item.Pinned ? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <g clip-path="url(#clip0_5457_81698)">
                                        <path
                                          d="M22.0642 9.74884L17.0513 14.7785C17.4788 15.9663 17.656 17.9557 15.8148 20.4035C15.686 20.5756 15.5216 20.7179 15.3328 20.8207C15.1439 20.9234 14.9352 20.9842 14.7207 20.9988C14.6851 20.9988 14.6504 20.9988 14.6148 20.9988C14.4177 20.9989 14.2225 20.9601 14.0404 20.8846C13.8583 20.8092 13.6929 20.6986 13.5535 20.5591L9.02915 16.0301L5.03259 20.0295C4.89186 20.1702 4.70098 20.2493 4.50196 20.2493C4.30294 20.2493 4.11207 20.1702 3.97134 20.0295C3.8306 19.8887 3.75154 19.6979 3.75154 19.4988C3.75154 19.2998 3.8306 19.1089 3.97134 18.9682L7.97071 14.9717L3.44259 10.4435C3.29375 10.2945 3.17796 10.1158 3.10276 9.91906C3.02757 9.72232 2.99465 9.51195 3.00614 9.30165C3.01764 9.09134 3.0733 8.88581 3.16949 8.69844C3.26569 8.51107 3.40026 8.34606 3.56446 8.21415C5.94759 6.29134 8.22852 6.66915 9.22696 6.98227L14.252 1.93946C14.3913 1.80014 14.5566 1.68962 14.7386 1.61421C14.9206 1.53881 15.1157 1.5 15.3127 1.5C15.5098 1.5 15.7048 1.53881 15.8868 1.61421C16.0689 1.68962 16.2342 1.80014 16.3735 1.93946L22.0632 7.62821C22.3444 7.90936 22.5025 8.29067 22.5027 8.68833C22.5029 9.08598 22.3451 9.46744 22.0642 9.74884Z"
                                          fill="#F65C06"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_5457_81698">
                                          <rect
                                            width="24"
                                            height="24"
                                            fill="white"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <g clip-path="url(#clip0_5457_81722)">
                                        <path
                                          d="M22.0642 9.74884L17.0513 14.7785C17.4788 15.9663 17.656 17.9557 15.8148 20.4035C15.686 20.5756 15.5216 20.7179 15.3328 20.8207C15.1439 20.9234 14.9352 20.9842 14.7207 20.9988C14.6851 20.9988 14.6504 20.9988 14.6148 20.9988C14.4177 20.9989 14.2225 20.9601 14.0404 20.8846C13.8583 20.8092 13.6929 20.6986 13.5535 20.5591L9.02915 16.0301L5.03259 20.0295C4.89186 20.1702 4.70098 20.2493 4.50196 20.2493C4.30294 20.2493 4.11207 20.1702 3.97134 20.0295C3.8306 19.8887 3.75154 19.6979 3.75154 19.4988C3.75154 19.2998 3.8306 19.1089 3.97134 18.9682L7.97071 14.9717L3.44259 10.4435C3.29375 10.2945 3.17796 10.1158 3.10276 9.91906C3.02757 9.72232 2.99465 9.51195 3.00614 9.30165C3.01764 9.09134 3.0733 8.88581 3.16949 8.69844C3.26569 8.51107 3.40026 8.34606 3.56446 8.21415C5.94759 6.29134 8.22852 6.66915 9.22696 6.98227L14.252 1.93946C14.3913 1.80014 14.5566 1.68962 14.7386 1.61421C14.9206 1.53881 15.1157 1.5 15.3127 1.5C15.5098 1.5 15.7048 1.53881 15.8868 1.61421C16.0689 1.68962 16.2342 1.80014 16.3735 1.93946L22.0632 7.62821C22.3444 7.90936 22.5025 8.29067 22.5027 8.68833C22.5029 9.08598 22.3451 9.46744 22.0642 9.74884Z"
                                          fill="#C5C5C5"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_5457_81722">
                                          <rect
                                            width="24"
                                            height="24"
                                            fill="white"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  )}
                                  {/* <i
                                    className={`note_pin_icon fas fa-thumbtack${
                                      item.Pinned ? " text_orange" : ""
                                    }`}
                                  ></i> */}
                                  {/* <i className="note_pin_icon fas fa-thumbtack" style={{ color: item.ColourCode }}></i> */}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mt-2-5">
                          <span
                            dangerouslySetInnerHTML={{
                              __html:
                                item !== null
                                  ? DOMPurify.sanitize(item.Description)
                                  : undefined,
                            }}
                            className="remove_paragraph_margin body_2_dark description-text note_card_content"
                          ></span>
                          <span
                            className="note_card_content_transparentDiv"
                            style={{
                              background: `linear-gradient(to bottom, transparent, ${colorCodeName})`,
                            }}
                          ></span>
                        </div>
                      </CustomCardWidget>
                    </div>
                  );
                })}
              </div>
            ) : (
              <RAFEmptyState
                title={"You do not have any notes."}
                body={`To get started, please add a new ${CareEsioEntity.ClientNote.DisplayName} by clicking on the "Add" button.`}
              />
            )}
            <div>
              {state.showManageClientNoteContent && (
                <DialogComponent
                  //  header={isNotNullAndUndefined(state.selectedObjectUID) ? "Update Note" : 'Add Note'}
                  //showCloseIcon={false}
                  visible={state.showManageClientNoteContent}
                  cssClass={
                    "rightDialog createEditForm full-height dlg-new-style"
                  }
                  id="manageupdate_clientnote_dialog"
                  content={manageClientNoteContent.bind(this)}
                  isModal
                  target="body"
                  closeOnEscape={false}
                  close={closeManageClientNoteDialog.bind(this)}
                  open={PreventFocusOnDialogOpen}
                  zIndex={1200}
                />
              )}
            </div>
          </RAFPermissionRender>
        ) : (
          <ACLoadingPanel loadingText="Loading..." />
        )}
      </div>
    </div>
  );
}

export default React.memo(ClientNoteList);
