import { Internationalization } from "@syncfusion/ej2-base";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import {
  Day,
  EventSettingsModel,
  Inject,
  ScheduleComponent,
  TimelineViews,
  ViewDirective,
  ViewsDirective,
} from "@syncfusion/ej2-react-schedule";
import moment from "moment";
import React, {
  PropsWithChildren,
  ReactElement,
  Reducer,
  forwardRef,
  useContext,
  useImperativeHandle,
  useReducer,
  useRef,
} from "react";
import { RolePermissionsContext } from "../../../../RAFAuthentication/RAFRolePermissionsContextProvider";
import { getFormatedDate } from "../../../../RAFComponents/Inputs/RFFUtils";
import RAFButtonComponent from "../../../../RAFComponents/Navigation/RAFButtonComponent";
import { showWarningToast } from "../../../../RAFComponents/Utility/RAFToastComponent";
import { hasPermissions } from "../../../../RAFComponents/helpers/PermissionHelper";
import {
  hexToRGBA,
  isNotEmptyArray,
  isNotNullAndUndefined,
} from "../../../../RAFComponents/helpers/utils";
import { ServiceTransactionPermissionConstants } from "../../../../constants/CareESIO/CareESIOPermissionConstant";
import {
  BrowserIsDevice,
  RAFButtonConstant,
} from "../../../../constants/Common/Constants";
import { ServiceTransactionRow } from "../../../ActiveContacts/ServiceTransaction/ServiceTransactionRow";
import "../../../ActiveContacts/ServiceTransaction/ServiceTransactionStyle.scss";
import { ShiftActivityTitle } from "../CareShiftLogHelper";

class SchedulesRowDataRow {
  Id: string;
  Subject: string;
  StartTime: Date;
  EndTime: Date;
  StartActualTime: Date;
  EndActualTime: Date;
  hasEndTime: boolean;
}

interface IProps {
  serviceTransactionRow: ServiceTransactionRow;
  careShiftLogsPropsValue: any[];
  calenderDataSourcePropsValue: SchedulesRowDataRow[];
  shiftStartDate: Date;
  shiftEndDate: Date;

  onClickShowEventDetails: (
    item: SchedulesRowDataRow,
    careShiftLogs: any[]
  ) => void;
  OnCellCreateClick: (StartTime: Date) => void;
  groupBtnContent: ReactElement;
}

interface IState {
  careShiftLogsStateValue: any[];
  calenderDataSourceStateValue: SchedulesRowDataRow[];
}

const CareShiftLogCalenderContent = forwardRef(
  function CareShiftLogCalenderContent(
    { ...props }: PropsWithChildren<IProps>,
    ref
  ) {
    const scheduleObjRef = useRef<ScheduleComponent>(null);

    let instance = new Internationalization();
    const rolePermissionsContext = useContext(RolePermissionsContext);
    const permissionValue = isNotNullAndUndefined(rolePermissionsContext)
      ? rolePermissionsContext.permissionValue
      : null;

    const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
      (state, newState) => ({ ...state, ...newState }),
      {
        careShiftLogsStateValue: props.careShiftLogsPropsValue,
        calenderDataSourceStateValue: props.calenderDataSourcePropsValue,
      }
    );

    useImperativeHandle(ref, () => ({
      onDestroyed() {
        if (
          isNotNullAndUndefined(scheduleObjRef) &&
          isNotNullAndUndefined(scheduleObjRef.current)
        ) {
          scheduleObjRef.current.destroy();
        }
      },
      updateDataSource(
        newDataSource: SchedulesRowDataRow[],
        newCareShiftLogs: any[]
      ) {
        if (
          isNotNullAndUndefined(scheduleObjRef) &&
          isNotNullAndUndefined(scheduleObjRef.current)
        ) {
          state.calenderDataSourceStateValue = newDataSource;
          state.careShiftLogsStateValue = newCareShiftLogs;
          scheduleObjRef.current.eventSettings.dataSource = newDataSource;
          scheduleObjRef.current.refreshEvents(); // Refresh only events
        }
      },
    }));

    const cellClick = (e) => {
      if (isNotNullAndUndefined(e)) {
        const startTime = e.startTime;

        OnCellCreateClick(startTime);
      }
    };

    // const onCellAddButtonClick = (e) => {
    //   if (isNotNullAndUndefined(e)) {
    //     const startTime = e.date;

    //     OnCellCreateClick(startTime);
    //   }
    // };

    const OnCellCreateClick = (startTime: Date) => {
      if (isNotNullAndUndefined(props.serviceTransactionRow.ActualStartDate)) {
        if (props.OnCellCreateClick) {
          props.OnCellCreateClick(startTime);
        }
      } else {
        showWarningToast("Start the shift to add activity.");
      }
    };

    const onClickShowEventDetails = (item: SchedulesRowDataRow) => {
      if (props.onClickShowEventDetails) {
        props.onClickShowEventDetails(item, state.careShiftLogsStateValue);
      }
    };

    const cellTemplate = (args: any) => {
      const hasPermissionToAdd = hasPermissions(permissionValue, [
        ServiceTransactionPermissionConstants.ServiceTransactionMyShiftsEnableActivities,
        ServiceTransactionPermissionConstants.ServiceTransactionMyShiftsEnableForms,
      ]);

      if (
        !BrowserIsDevice &&
        hasPermissionToAdd &&
        args.type !== "alldayCells"
      ) {
        const { calenderDataSourceStateValue } = state;

        if (isNotEmptyArray(calenderDataSourceStateValue)) {
          const timeRange = new Date(args.date);
          const currentTimeRangeItem = calenderDataSourceStateValue.find(
            (item) => {
              const itemStartDate = new Date(item.StartTime);
              return itemStartDate >= timeRange && itemStartDate <= timeRange;
            }
          );
          if (isNotEmptyArray(currentTimeRangeItem)) {
            return <></>;
          }
        }

        return (
          <div className="p-2 cellTemplateChild cursor">
            <div className="row gx-2 justify-content-center">
              <div className="col-auto">
                <RAFButtonComponent
                  className="link-button h-auto cellTemplateChild_btn"
                  action={RAFButtonConstant.Add}
                  btnContent="Add Shift Log"
                  isPrimary
                  enableToolTip={false}
                />
              </div>
            </div>
          </div>
        );
      } else {
        return null;
      }
    };

    const eventTemplate = (args: SchedulesRowDataRow) => {
      const currentItemID = args.Id;

      const colorCodeName = "#000000";

      const value = args.Subject;

      const isStartOrCompleteShift =
        value === ShiftActivityTitle.StartedShift ||
        value === ShiftActivityTitle.CompletedShift;

      return (
        <div
          key={currentItemID}
          className="template-wrap d-flex align-items-start justify-content-between h-100"
          style={{
            backgroundColor: hexToRGBA(
              isNotNullAndUndefined(colorCodeName) ? colorCodeName : "#000000",
              0.1
            ),
            // borderLeft: `4px solid ${colorCodeName}`,
            color: "#2b2f32",
            cursor: "pointer",
          }}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onClickShowEventDetails(args);
          }}
        >
          <div>
            <div className="subtitle_3 semi_bold">
              <span> {value ?? "Not set"} </span>
            </div>
            <div className="d-flex align-items-center pt-0-5">
              <div
                className="template-wrap-border"
                style={{
                  borderLeft: `2px solid ${colorCodeName}`,
                  backgroundColor: isNotNullAndUndefined(colorCodeName)
                    ? colorCodeName
                    : "#000000",
                  color: "#2b2f32",
                }}
              ></div>
              {args.hasEndTime && !isStartOrCompleteShift ? (
                <div className="body_3">
                  {`${moment(args.StartActualTime).format("h:mm a")} - ${moment(
                    args.EndActualTime
                  ).format("h:mm a")}`}
                </div>
              ) : (
                <div className="body_3">
                  {`${moment(args.StartActualTime).format("h:mm a")}`}
                </div>
              )}
            </div>
            <div className="d-flex mt-1"></div>
            <div className=""></div>
            <div className="d-none" id="event-template-wrap-progressDiv"></div>
          </div>
        </div>
      );
    };

    const onEventClick = (args) => {
      args.cancel = true; // Prevents the default cell click action
    };

    const getDateHeaderText = (value) => {
      const dateValue = instance.formatDate(value, { skeleton: "d" });
      const dayValue = getFormatedDate(value, "ddd");
      // const dayValue = instance.formatDate(value, { skeleton: 'E' });
      return (
        <div className="text-center subtitle_2">
          <span className="subtitle_2 bold">{`${dateValue} ${dayValue}`}</span>
        </div>
      );
    };

    const dateHeaderTemplate = (args) => {
      return (
        <div className="py-2">
          <div className="text-center subtitle_2">
            <span className="subtitle_2 bold">{`Tap on a time slot to Add Shift Log`}</span>
          </div>
          {/* {getDateHeaderText(args.date)} */}
        </div>
      );
    };

    const eventSettings: EventSettingsModel = {
      dataSource: state.calenderDataSourceStateValue,
      template: eventTemplate,
      enableTooltip: false,
      allowAdding: false,
      allowEditing: false,
      allowDeleting: false,
      editFollowingEvents: false,
    };

    const onClickGroupBtn = (idString: string) => {
      const tabItems = document.querySelectorAll(".btn_group_item");
      const selectedTabItem = document.getElementById(idString);
      tabItems.forEach((item) => {
        item.classList.remove("active");
      });
      if (isNotNullAndUndefined(selectedTabItem)) {
        selectedTabItem.classList.add("active");
      }
    };

    const majorSlotTemplate = (args) => {
      const date = new Date(args.date);

      return (
        <div className="py-2">
          <div className="text-center">
            <span>{`${moment(date).format("hh:mm A")}`}</span>
          </div>
        </div>
      );
    };

    function generateDaysArray(minTimeValue: Date, maxTimeValue: Date) {
      const start = moment(minTimeValue);
      const end = moment(maxTimeValue);

      const adjustedStart = start.startOf("day");
      const adjustedEnd = end.startOf("day");

      const numberOfDays = adjustedEnd.diff(adjustedStart, "days") + 1;

      let days = [];
      let currentDate = moment(adjustedStart);
      const step =
        moment(adjustedEnd).diff(moment(adjustedStart), "days") /
        (numberOfDays - 1);

      for (let i = 0; i < numberOfDays; i++) {
        days.push({
          dateValue: currentDate.toDate(),
          startHour: i === 0 ? moment(adjustedStart).format("HH:mm") : "00:00",
          endHour:
            i === numberOfDays - 1
              ? moment(adjustedEnd).format("HH:mm")
              : "23:59",
        });
        currentDate = currentDate.add(step, "days");
      }

      return days;
    }

    const getHeaderDateSelectionBtns = (
      minTimeValue: Date,
      maxTimeValue: Date,
      minTime: string
    ) => {
      const isStartDateAndEndDateSameDay = moment(minTimeValue).isSame(
        maxTimeValue,
        "day"
      );

      if (!isStartDateAndEndDateSameDay) {
        const days = generateDaysArray(minTimeValue, maxTimeValue); // Example for 3 days
        const daysLength = isNotEmptyArray(days) ? days.length : 0;
        return (
          <div className="btn_group_container" key={daysLength}>
            <div className="row gx-0">
              {days.map((day, index) => {
                const idString = `group_date_item_${index}_${daysLength}`;
                return (
                  <div className="col-auto" key={index}>
                    <ButtonComponent
                      key={idString}
                      content={`${moment(day.dateValue).format("MMM DD")}`}
                      onClick={() => {
                        const selectedDate: Date = day.dateValue;
                        const scheduleObjRefSelectedDate: Date =
                          scheduleObjRef.current.selectedDate;
                        const isActiveDate =
                          isNotNullAndUndefined(scheduleObjRefSelectedDate) &&
                          moment(selectedDate).isSame(
                            scheduleObjRefSelectedDate,
                            "day"
                          );
                        if (isActiveDate) {
                          return;
                        }
                        scheduleObjRef.current.selectedDate = selectedDate;
                        if (index === 0) {
                          scheduleObjRef.current.startHour = minTime;
                          scheduleObjRef.current.endHour = "23:59";
                        } else if (index === daysLength - 1) {
                          scheduleObjRef.current.startHour = "00:00";
                          scheduleObjRef.current.endHour = moment(
                            new Date(maxTimeValue)
                          )
                            .format("HH:mm")
                            .toString();
                        } else {
                          scheduleObjRef.current.startHour =
                            day.startHour || "00:00";
                          scheduleObjRef.current.endHour =
                            day.endHour || "23:59";
                        }
                        onClickGroupBtn(idString);
                      }}
                      id={idString}
                      className={`px-2 btn_brand_primary btn_group_item ${
                        index === 0 ? "active" : ""
                      }`}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        );
      } else {
        return <> </>;
      }
    };

    const getHeaderDateSelectionBtns1 = (
      minTimeValue: Date,
      maxTimeValue: Date,
      minTime: string
    ) => {
      const isStartDateAndEndDateSameDay = moment(minTimeValue).isSame(
        maxTimeValue,
        "day"
      );

      if (!isStartDateAndEndDateSameDay) {
        return (
          <div className="btn_group_container">
            <div className="row gx-0">
              <div className="col-auto">
                <RAFButtonComponent
                  btnContent={`${moment(minTimeValue).format("MMM DD")}`}
                  onClick={() => {
                    scheduleObjRef.current.selectedDate = minTimeValue;
                    scheduleObjRef.current.startHour = minTime;
                    scheduleObjRef.current.endHour = "23:59";
                    onClickGroupBtn("btn__group_start_date_item");
                  }}
                  idString="group_start_date_item"
                  className={`px-2 btn_brand_primary btn_group_item active`}
                />
              </div>
              <div className="col-auto">
                <RAFButtonComponent
                  btnContent={`${moment(maxTimeValue).format("MMM DD")}`}
                  onClick={() => {
                    scheduleObjRef.current.selectedDate = maxTimeValue;
                    scheduleObjRef.current.startHour = "00:00";
                    scheduleObjRef.current.endHour = moment(
                      new Date(maxTimeValue)
                    )
                      .format("HH:mm")
                      .toString();
                    onClickGroupBtn("btn__group_end_date_item");
                  }}
                  idString="group_end_date_item"
                  className={`px-2 btn_brand_primary btn_group_item`}
                />
              </div>
            </div>
          </div>
        );
      } else {
        return <> </>;
      }
    };

    const getContent = () => {
      const { shiftStartDate, shiftEndDate } = props;

      const minTimeValue = new Date(shiftStartDate);
      minTimeValue.setMinutes(minTimeValue.getMinutes() - 30);

      const maxTimeValue = new Date(shiftEndDate);
      maxTimeValue.setMinutes(maxTimeValue.getMinutes() + 30);

      const isStartDateAndEndDateSameDay = moment(minTimeValue).isSame(
        maxTimeValue,
        "day"
      );

      const minTime = moment(new Date(minTimeValue)).format("HH:mm").toString();
      const maxTime = isStartDateAndEndDateSameDay
        ? moment(new Date(maxTimeValue)).format("HH:mm").toString()
        : "23:59";

      return (
        <>
          <div className="row gx-2 gx-md-3 align-items-center justify-content-between flex-nowrap">
            <div className="col-auto">
              {getHeaderDateSelectionBtns(minTimeValue, maxTimeValue, minTime)}
            </div>
            {isNotNullAndUndefined(props.groupBtnContent) ? (
              <div className="col-auto">{props.groupBtnContent}</div>
            ) : (
              ""
            )}
          </div>
          <ScheduleComponent
            cssClass={`resource raf_schedule schedule-cell-dimension withWidth`}
            id="raf_schedule"
            ref={scheduleObjRef}
            eventSettings={eventSettings}
            showQuickInfo={true}
            quickInfoOnSelectionEnd={true}
            cellTemplate={cellTemplate}
            hover={(args) => {
              args.cancel = true; // Prevents the default cell hover action
            }}
            cellClick={(args) => {
              const isAllDay = args.isAllDay;
              if (isAllDay) {
                OnCellCreateClick(shiftStartDate);
              } else {
                cellClick(args);
              }
              args.cancel = true; // Prevents the default cell click action
            }}
            cellDoubleClick={(args) => {
              args.cancel = true; // Prevents the default cell double click action
            }}
            eventClick={(args) => {
              args.cancel = true; // Prevents the default cell click action
              onEventClick(args);
            }}
            eventDoubleClick={(args) => {
              args.cancel = true; // Prevents the default cell click action
            }}
            allowMultiCellSelection={false}
            allowMultiDrag={false}
            timeScale={{
              enable: true,
              interval: 30,
              slotCount: 1,
              majorSlotTemplate: majorSlotTemplate,
              minorSlotTemplate: null,
            }}
            startHour={minTime}
            endHour={maxTime}
            selectedDate={minTimeValue}
            rowAutoHeight={true}
            currentView={"Day"}
            showHeaderBar={false}
            allowDragAndDrop={false}
            allowSwiping={false}
            enableAdaptiveUI={BrowserIsDevice ? true : false}
            dateHeaderTemplate={dateHeaderTemplate}
          >
            <ViewsDirective>
              <ViewDirective option="Day" eventTemplate={eventTemplate} />
            </ViewsDirective>
            <Inject services={[TimelineViews, Day]} />
          </ScheduleComponent>
        </>
      );
    };

    return getContent();
  }
);

export default React.memo(CareShiftLogCalenderContent);
